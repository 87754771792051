import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Skeleton,
  Container,
  Img,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PelatihanImage from "../../assets/webp/img_pelatihan.webp";
import KnowledgeImage from "../../assets/webp/img_pengetahuan.webp";

const TrainingKnowledge: React.FC = () => {
  const history = useHistory();

  const handleNavigate = (path: string) => {
    history.push(path);
    window.scrollTo(0, 0);
  };

  return (
    <Box bg="white">
      <Container maxW="8xl" my={{ base: 8, md: 12 }}>
        <Text fontSize={{ base: "xl", md: "2xl" }} fontWeight="semibold">
          Pelatihan dan Pengetahuan
        </Text>
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            rounded="lg"
            overflow="hidden"
            shadow="lg"
            my={4}
            w={{ base: "100&", md: "49%" }}
            position="relative"
            h={{ base: "380px", md: "600px" }}
          >
            <Img
              w="full"
              src={PelatihanImage}
              fallback={<Skeleton h="full" w="full" />}
              alt="Gambar Pelatihan"
              objectFit="cover"
              position="relative"
              mb={{ base: 0, md: 12 }}
            />
            <Box
              position={{ base: "relative", md: "absolute" }}
              bottom={0}
              w="full"
              backgroundColor="white"
            >
              <Box px={6} py={4}>
                <Text fontWeight="semibold" fontSize="xl" mb={2} color="black">
                  Pelatihan
                </Text>
                <Text fontSize="sm" color="black">
                  Ikuti Pelatihan untuk tingkatkan kompetensi anda
                </Text>
              </Box>
              <Box px={6} pb={4}>
                <Button
                  type="button"
                  color="white"
                  bg="green.700"
                  rounded="md"
                  p={2}
                  w="full"
                  _hover={{ bg: "green.800" }}
                  _active={{ bg: "green.800" }}
                  onClick={() => handleNavigate("/pelatihan")}
                >
                  Selengkapnya
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            rounded="lg"
            overflow="hidden"
            shadow="lg"
            my={4}
            w={{ base: "100&", md: "49%" }}
            position="relative"
            h={{ base: "380px", md: "600px" }}
          >
            <Img
              w="full"
              fallback={<Skeleton h="full" w="full" />}
              src={KnowledgeImage}
              alt="Gambar Knowledge"
              objectFit="cover"
              position="relative"
              mb={{ base: 0, md: 12 }}
            />
            <Box
              position={{ base: "relative", md: "absolute" }}
              bottom={0}
              w="full"
              backgroundColor="white"
            >
              <Box px={6} py={4}>
                <Text fontWeight="semibold" fontSize="xl" mb={2} color="black">
                  Pengetahuan
                </Text>
                <Text fontSize="sm" color="black">
                  Ikuti materi Pengetahuan untuk tingkatkan kualifikasi anda
                </Text>
              </Box>
              <Box px={6} pb={4}>
                <Button
                  type="button"
                  color="white"
                  bg="green.700"
                  rounded="md"
                  p={2}
                  w="full"
                  _hover={{ bg: "green.800" }}
                  _active={{ bg: "green.800" }}
                  onClick={() => handleNavigate("/pengetahuan")}
                >
                  Selengkapnya
                </Button>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default TrainingKnowledge;
