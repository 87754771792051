/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../../data/presenters/PelatihanPresenter";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import moment from "moment";
import { Pelatihan } from "../../../../../../entities/Pelatihan";
import { JenisPelatihan } from "../../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../../data/presenters/JenisPelatihanPresenter";
import { SubMenuJenisPelatihan } from "../../../../../../entities/MenuJenisPelatihan";
import { MenuJenisPelatihanPresenter } from "../../../../../../data/presenters/MenuJenisPelatihanPresenter";

interface IState {
  isLoading: boolean;
  path_thumbnail: any;
  jenis_pelatihan_id: number;
  sub_menu_jenis_pelatihan_id: number;
  waktu_mulai: string;
  waktu_selesai: string;
  waktu_mulai_pendaftaran: string;
  waktu_selesai_pendaftaran: string;
  penyelenggara: string;
  informasi_umum: string;
  kompetensi_teknis: string;
  persyaratan: string;
  angkatan: string;
  pelatihan: Pelatihan | null;
  jenisPelatihan: JenisPelatihan[];
  subMenuJenisPelatihan: SubMenuJenisPelatihan[];
  status_publish: boolean;
  isPPKB: boolean;
  materiInti: string;
  isForGuruMadrasah: boolean;
}

interface InitialState {
  state: IState;
  updatePelatihan: Function;
  setPathThumbnail: Function;
  setJenisPelatihanId: Function;
  setSubMenuJenisPelatihanId: Function;
  setWaktuMulai: Function;
  setWaktuSelesai: Function;
  setWaktuMulaiPendaftaran: Function;
  setWaktuSelesaiPendaftaran: Function;
  setPenyelenggara: Function;
  setInformasiUmum: Function;
  setKompetensiTeknis: Function;
  setPersyaratan: Function;
  setAngkatan: Function;
  getDetailPelatihan: Function;
  getJenisPelatihan: Function;
  getSubMenuJenisPelatihan: Function;
  setIsPPKB: Function;
  setUpdateManajemenPelatihan: Function;
}

const initialState = {
  state: {
    isLoading: false,
    path_thumbnail: null,
    jenis_pelatihan_id: 0,
    sub_menu_jenis_pelatihan_id: 0,
    waktu_mulai: "",
    waktu_selesai: "",
    waktu_mulai_pendaftaran: "",
    waktu_selesai_pendaftaran: "",
    penyelenggara: "",
    informasi_umum: "",
    kompetensi_teknis: "",
    persyaratan: "",
    angkatan: "",
    pelatihan: null,
    jenisPelatihan: [],
    subMenuJenisPelatihan: [],
    status_publish: false,
    isPPKB: false,
    materiInti: "",
    isForGuruMadrasah: false,
  },
  updatePelatihan: () => {},
  setPathThumbnail: () => {},
  setJenisPelatihanId: () => {},
  setSubMenuJenisPelatihanId: () => {},
  setWaktuMulai: () => {},
  setWaktuSelesai: () => {},
  setWaktuMulaiPendaftaran: () => {},
  setWaktuSelesaiPendaftaran: () => {},
  setPenyelenggara: () => {},
  setInformasiUmum: () => {},
  setKompetensiTeknis: () => {},
  setPersyaratan: () => {},
  setAngkatan: () => {},
  getDetailPelatihan: () => {},
  getJenisPelatihan: () => {},
  getSubMenuJenisPelatihan: () => {},
  setIsPPKB: () => {},
  setUpdateManajemenPelatihan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenPelatihanUpdateProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    path_thumbnail: null,
    jenis_pelatihan_id: 0,
    sub_menu_jenis_pelatihan_id: 0,
    waktu_mulai: "",
    waktu_selesai: "",
    waktu_mulai_pendaftaran: "",
    waktu_selesai_pendaftaran: "",
    penyelenggara: "",
    informasi_umum: "",
    kompetensi_teknis: "",
    persyaratan: "",
    angkatan: "",
    pelatihan: null,
    jenisPelatihan: [],
    subMenuJenisPelatihan: [],
    status_publish: false,
    isPPKB: false,
    materiInti: "",
    isForGuruMadrasah: false,
  });

  const setUpdateManajemenPelatihan = <K extends keyof IState>(
    key: K,
    value: IState[K]
  ) => {
    setState((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const updatePelatihan = async (id: number) => {
    setLoading(true);

    if (
      state.jenis_pelatihan_id == 0 ||
      state.sub_menu_jenis_pelatihan_id == 0 ||
      state.waktu_mulai == "" ||
      state.waktu_selesai == "" ||
      state.waktu_mulai_pendaftaran == "" ||
      state.waktu_selesai_pendaftaran == "" ||
      state.penyelenggara == "" ||
      state.informasi_umum == "" ||
      state.persyaratan == "" ||
      state.angkatan == ""
    ) {
      toast("Data Belum Terisi Lengkap!");
      setLoading(false);
      return;
    }

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);

      const update = await pelatihanPresenter.updatePelatihan(
        id,
        state.path_thumbnail,
        state.sub_menu_jenis_pelatihan_id,
        state.jenis_pelatihan_id,
        moment(state.waktu_mulai).format("YYYY-MM-DD HH:mm:ss"),
        moment(state.waktu_selesai).format("YYYY-MM-DD HH:mm:ss"),
        moment(state.waktu_mulai_pendaftaran).format("YYYY-MM-DD HH:mm:ss"),
        moment(state.waktu_selesai_pendaftaran).format("YYYY-MM-DD HH:mm:ss"),
        state.penyelenggara,
        state.informasi_umum,
        state.kompetensi_teknis,
        state.persyaratan,
        state.angkatan,
        state.status_publish,
        state.isPPKB,
        state.isPPKB ? state.materiInti : "",
        state.isForGuruMadrasah
      );

      const data = update;

      if (data.data.code == 200) {
        toast("Berhasil Ubah Data .");

        history.replace(
          `/admin/kelola_konten/manajemen_pelatihan/${data.data.data.id}`
        );
        window.location.reload();
      } else {
        toast("Ada Kesalahan Saat Ubah Data!");
      }
      setLoading(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      toast("Ada Kesalahan Saat Ubah Data!");
      setLoading(false);
    }
  };

  const setPathThumbnail = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_thumbnail: value,
    }));
  };

  const setJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jenis_pelatihan_id: value,
    }));
  };

  const setSubMenuJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      sub_menu_jenis_pelatihan_id: value,
    }));
  };

  const setWaktuMulai = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_mulai: value,
    }));
  };

  const setWaktuSelesai = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_selesai: value,
    }));
  };

  const setWaktuMulaiPendaftaran = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_mulai_pendaftaran: value,
    }));
  };

  const setWaktuSelesaiPendaftaran = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_selesai_pendaftaran: value,
    }));
  };

  const setPenyelenggara = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      penyelenggara: value,
    }));
  };

  const setInformasiUmum = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      informasi_umum: value,
    }));
  };

  const setKompetensiTeknis = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      kompetensi_teknis: value,
    }));
  };

  const setPersyaratan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      persyaratan: value,
    }));
  };

  const setAngkatan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      angkatan: value,
    }));
  };

  const setIsPPKB = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isPPKB: value,
    }));
  };

  const getDetailPelatihan = async (id: number) => {
    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        pelatihan: data,
        jenis_pelatihan_id: data.jenis_pelatihan_id,
        sub_menu_jenis_pelatihan_id: data.sub_menu_jenis_pelatihan_id,
        waktu_mulai: data.waktu_mulai,
        waktu_selesai: data.waktu_selesai,
        waktu_mulai_pendaftaran: data.waktu_mulai_pendaftaran,
        waktu_selesai_pendaftaran: data.waktu_selesai_pendaftaran,
        penyelenggara: data.penyelenggara,
        informasi_umum: data.informasi_umum,
        persyaratan: data.persyaratan,
        angkatan: data.angkatan,
        status_publish: data.status_publish === 1 ? true : false,
        kompetensi_teknis: data.kompetensi_teknis,
        isPPKB: data.is_ppkb === 1 ? true : false,
        materiInti: data.materi_inti,
        path_thumbnail: data.path_thumbnail,
        isForGuruMadrasah: data.is_for_guru_madrasah === 1 ? true : false,
      }));

      getJenisPelatihan(data.sub_menu_jenis_pelatihan_id.toString());
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  const getJenisPelatihan = async (sub_menu_jenis_pelatihan_id: string) => {
    try {
      const jenisPelatihan = container.resolve(JenisPelatihanPresenter);

      const get = await jenisPelatihan.getAllJenisPelatihan(
        1,
        10000000,
        "",
        sub_menu_jenis_pelatihan_id
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const getSubMenuJenisPelatihan = async () => {
    try {
      const menuJenisPelatihanPresenter = container.resolve(
        MenuJenisPelatihanPresenter
      );

      const get = await menuJenisPelatihanPresenter.getAllSubMenuJenisPelatihan(
        1,
        10000000,
        ""
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        subMenuJenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Sub Menu Jenis Pelatihan:", error);
    }
  };

  return (
    <ManajemenPelatihanUpdateProvider
      value={{
        state,
        updatePelatihan,
        setPathThumbnail,
        setJenisPelatihanId,
        setSubMenuJenisPelatihanId,
        setWaktuMulai,
        setWaktuSelesai,
        setWaktuMulaiPendaftaran,
        setWaktuSelesaiPendaftaran,
        setPenyelenggara,
        setInformasiUmum,
        setKompetensiTeknis,
        setPersyaratan,
        setAngkatan,
        getDetailPelatihan,
        getJenisPelatihan,
        getSubMenuJenisPelatihan,
        setIsPPKB,
        setUpdateManajemenPelatihan,
      }}
    >
      {children}
    </ManajemenPelatihanUpdateProvider>
  );
};

export const useManajemenPelatihanUpdateContext = () =>
  React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenPelatihanUpdateContext,
  Provider,
};
