export class PelatihanSaya {
  id: number;
  user_id: number;
  pelatihan_id: number;
  jenis_pelatihan_id: number;
  path_thumbnail: string;
  path_sertifikat: string;
  status_pelatihan: string;
  nilai_akhir: number;
  predikat: string;
  judul_pelatihan: string;
  penyelenggara: string;
  waktu_mulai: string;
  waktu_selesai: string;
  modul_selesai: number;
  modul_belum: number;
  peserta: Peserta;
  jumlahData: number;
  status_tte: string;

  constructor(
    id: number,
    user_id: number,
    pelatihan_id: number,
    jenis_pelatihan_id: number,
    path_thumbnail: string,
    path_sertifikat: string,
    status_pelatihan: string,
    nilai_akhir: number,
    predikat: string,
    judul_pelatihan: string,
    penyelenggara: string,
    waktu_mulai: string,
    waktu_selesai: string,
    modul_selesai: number,
    modul_belum: number,
    peserta: Peserta,
    jumlahData: number,
    status_tte: string
  ) {
    this.id = id;
    this.user_id = user_id;
    this.pelatihan_id = pelatihan_id;
    this.jenis_pelatihan_id = jenis_pelatihan_id;
    this.path_thumbnail = path_thumbnail;
    this.path_sertifikat = path_sertifikat;
    this.status_pelatihan = status_pelatihan;
    this.nilai_akhir = nilai_akhir;
    this.predikat = predikat;
    this.judul_pelatihan = judul_pelatihan;
    this.penyelenggara = penyelenggara;
    this.waktu_mulai = waktu_mulai;
    this.waktu_selesai = waktu_selesai;
    this.modul_selesai = modul_selesai;
    this.modul_belum = modul_belum;
    this.peserta = peserta;
    this.jumlahData = jumlahData;
    this.status_tte = status_tte;
  }
}

export class Peserta {
  id: number;
  name: string;
  nip: string;
  pangkat: string;
  gol_ruang: string;
  level_jabatan: string;

  constructor(
    id: number,
    name: string,
    nip: string,
    pangkat: string,
    gol_ruang: string,
    level_jabatan: string
  ) {
    this.id = id;
    this.name = name;
    this.nip = nip;
    this.pangkat = pangkat;
    this.gol_ruang = gol_ruang;
    this.level_jabatan = level_jabatan;
  }
}
