import {
  Box,
  Text,
  Flex,
  Button,
  IconButton,
  Divider,
  Grid,
  GridItem,
  Input,
  Textarea,
  VStack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useManajemenPengetahuanContext } from "../../../controller/admin/manajemen_pengetahuan";
import { FaEdit, FaFilePdf } from "react-icons/fa";
import { FiEye, FiEyeOff } from "react-icons/fi";

type Props = {
  setShowModalAssesment: Function;
};

/**
 * Renders a box component that displays the details of an assessment.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setShowModalAssesment - A function to set the visibility of the assessment modal.
 * @return {JSX.Element} The rendered box component.
 * @author Bagas Prasetyadi
 */

const BoxDetailAssesment = ({ setShowModalAssesment }: Props) => {
  const { setManajemenPengetahuanState } = useManajemenPengetahuanContext();
  const { filesAssesment, titleAssesment, descriptionAssesment } =
    useManajemenPengetahuanContext().state;

  const [previewToggles, setPreviewToggles] = useState<boolean[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  const togglePreview = (index: number) => {
    setPreviewToggles((prevToggles) =>
      prevToggles.map((toggle, i) => (i === index ? !toggle : toggle))
    );
  };

  useEffect(() => {
    if (filesAssesment.length > 0) {
      setPreviewToggles(filesAssesment.map(() => false));
      setPreviews(
        filesAssesment.map((file) =>
          file.url ? file.url : URL.createObjectURL(file)
        )
      );
    }
  }, [filesAssesment]);

  return (
    <>
      {titleAssesment ? (
        <>
          <Flex alignItems="center">
            <Text fontWeight="semibold" fontSize="md" mr={3}>
              Detail Assesment
            </Text>
            <IconButton
              aria-label="Edit Assesment"
              icon={<FaEdit />}
              color="green.400"
              bg="transparent"
              fontSize="md"
              onClick={() => {
                setShowModalAssesment(true);
                setManajemenPengetahuanState("modalAssesmentMode", "Ubah");
              }}
            />
          </Flex>
          <Divider my={4} />

          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem colSpan={1}>Judul</GridItem>
            <GridItem colSpan={5}>
              <Input
                fontSize="sm"
                bg="#F6F5F8"
                value={titleAssesment}
                readOnly
              />
            </GridItem>
            <GridItem colSpan={1}>Deskripsi</GridItem>
            <GridItem colSpan={5}>
              <Textarea
                fontSize="sm"
                bg="#F6F5F8"
                value={descriptionAssesment}
                readOnly
              />
            </GridItem>
            <GridItem colSpan={1}>Bahan Tugas</GridItem>
            <GridItem colSpan={5}>
              <VStack spacing={3}>
                {filesAssesment.map((file, index) => (
                  <Box key={index} w="full">
                    <Box
                      borderWidth="1px"
                      borderRadius="md"
                      px={4}
                      py={2}
                      border="1px"
                      borderStyle="dashed"
                      borderColor="gray.500"
                    >
                      <HStack spacing={2} justify="space-between" w="full">
                        <Flex alignItems="center">
                          <Icon as={FaFilePdf} mr={2} color="red.500" />
                          <Text
                            fontSize="sm"
                            textAlign="left"
                            fontWeight="semibold"
                          >
                            {file.name}
                          </Text>
                        </Flex>
                        <HStack spacing={2}>
                          <Icon
                            as={previewToggles[index] ? FiEyeOff : FiEye}
                            cursor="pointer"
                            onClick={() => togglePreview(index)}
                          />
                        </HStack>
                      </HStack>
                      {previews[index] && previewToggles[index] && (
                        <Box mt={2}>
                          <iframe
                            src={previews[index]}
                            width="100%"
                            height="400px"
                            title={`preview-${index}`}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </VStack>
            </GridItem>
          </Grid>
        </>
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Text fontSize="md">Belum ada assesment</Text>
          </Box>
          <Button
            colorScheme="green"
            h={8}
            mt={2}
            onClick={() => {
              setShowModalAssesment(true);
              setManajemenPengetahuanState("modalAssesmentMode", "Tambah");
            }}
          >
            Tambakan Assesment
          </Button>
        </Flex>
      )}
    </>
  );
};

export default BoxDetailAssesment;
