import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../api/axiosInstance";

type ToastStatus = "error" | "info" | "warning" | "success";

interface Option {
  label: string;
  value: string;
}

export interface FileAssesment {
  id: number;
  assessment_id: number;
  path_file: string;
  nama_file: string;
  mime_type: string;
  created_at: string;
  updated_at: string;
}
interface PengetahuanItem {
  id: number;
  title: string;
  kategori_pengetahuan_id: string;
  description: string;
  category: string;
  path_file: string;
  total_view: number | null;
  review_score: number | null;
  status_publish: number;
  thumbnail: string;
  created_at: string;
  updated_at: string;
  nama_penyelenggara: string;
  jadwal_mulai: string;
  jadwal_selesai: string;
  link_streaming: string;
  jenis_pengetahuan: string;
  durasi_quiz: number;
  paket_soal_id: number;
  judul_asesmen: string;
  keterangan_asesmen: string;
  has_assessment: boolean;
  assessment: {
    assessment_files: FileAssesment[];
    judul: string;
    keterangan: string;
  };
}
interface IState {
  state: {
    title: string;
    organizer: string;
    kategoriPengetahuanId: Option | null;
    image: File | null;
    video: File | null;
    pdf: File | null;
    description: string;
    link: string;
    publish: boolean;
    url: string;
    urlKategoriPengetahuan: string;
    loadingData: boolean;
    loadingAction: boolean;
    listData: PengetahuanItem[];
    page: number;
    perPage: number;
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    category: Option | null;
    mode: string;
    itemId: string;
    listCategory: Option[];
    listKategoriPengetahuan: Option[];
    youtubeVideoSelected: Option | null;
    type: "synchronized" | "asynchronized";
    linkStream: string;
    startDate: string;
    endDate: string;
    paketSoalId: number;
    duration: number;
    hasQuiz: boolean;
    hasAssesment: boolean;
    modalQuizMode: "Tambah" | "Ubah";
    modalAssesmentMode: "Tambah" | "Ubah";
    titleAssesment: string;
    descriptionAssesment: string;
    filesAssesment: File[] | FileAssesment[];
  };
  setManajemenPengetahuanState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
  handleEdit: Function;
  clearState: Function;
  getListKategoriPengetahuan: Function;
}

const initialState: IState = {
  state: {
    title: "",
    organizer: "",
    kategoriPengetahuanId: null,
    image: null,
    video: null,
    pdf: null,
    description: "",
    link: "",
    publish: false,
    url: "management_content/pengetahuan",
    urlKategoriPengetahuan: "kategori_pengetahuan",
    loadingData: false,
    loadingAction: false,
    listData: [],
    page: 1,
    perPage: 9,
    pagination: null,
    category: null,
    mode: "",
    itemId: "",
    listCategory: [
      { value: "video", label: "Video" },
      { value: "pdf", label: "PDF" },
    ],
    listKategoriPengetahuan: [],
    youtubeVideoSelected: null,
    type: "asynchronized",
    linkStream: "",
    startDate: "",
    endDate: "",
    paketSoalId: 0,
    duration: 0,
    hasQuiz: false,
    modalQuizMode: "Tambah",
    hasAssesment: false,
    modalAssesmentMode: "Tambah",
    titleAssesment: "",
    descriptionAssesment: "",
    filesAssesment: [],
  },
  setManajemenPengetahuanState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  clearState: () => {},
  getListKategoriPengetahuan: () => {},
};

/**
 * The ManajemenPengetahuanContext provides a context for managing the state related to manajemen pengetahuan.
 * It includes state properties and functions to update the state.
 * @type {React.Context<IState>}
 * @author Bagas Prasetyadi
 */

const Context = createContext<IState>(initialState);
const { Provider: ManajemenPengetahuanProvider } = Context;

/**
 * The provider component that wraps its children with the ManajemenPengetahuanContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 */
const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const {
    perPage,
    page,
    url,
    urlKategoriPengetahuan,
    title,
    description,
    organizer,
    kategoriPengetahuanId,
    category,
    publish,
    listData,
    listCategory,
    itemId,
    mode,
    pdf,
    image,
    youtubeVideoSelected,
    listKategoriPengetahuan,
    linkStream,
    type,
    startDate,
    endDate,
    paketSoalId,
    duration,
    titleAssesment,
    descriptionAssesment,
    filesAssesment,
    hasQuiz,
    hasAssesment,
  } = state.state;

  const setManajemenPengetahuanState = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async (status?: string) => {
    setManajemenPengetahuanState("loadingData", true);
    try {
      const response = await axiosInstance.get(url, {
        params: {
          status_publish: status ?? "0,1",
          rows: perPage,
          page: page,
        },
      });

      let listData = response.data?.data;

      // Convert durasi_quiz from seconds to minutes
      if (listData && Array.isArray(listData)) {
        listData = listData.map((item) => {
          if (item.durasi_quiz) {
            return {
              ...item,
              durasi_quiz: item.durasi_quiz / 60, // Convert to minutes
            };
          }
          return item;
        });
      }

      setManajemenPengetahuanState("listData", listData);
      setManajemenPengetahuanState("pagination", response?.data.pagination);
      setManajemenPengetahuanState("loadingData", false);
    } catch (error) {
      setManajemenPengetahuanState("loadingData", false);
      throw error;
    }
  };

  const getListKategoriPengetahuan = async () => {
    try {
      const response = await axiosInstance.get(urlKategoriPengetahuan, {
        params: {
          paginate: false,
        },
      });

      const data = response.data.data.map((e) => {
        return {
          value: e.id,
          label: e.nama,
        };
      });

      setManajemenPengetahuanState("listKategoriPengetahuan", data);
    } catch (error) {
      throw error;
    }
  };

  const showToast = (title: string, status: ToastStatus = "error") => {
    toast({
      title,
      status,
      position: "top-right",
      duration: 3000,
      isClosable: true,
    });
  };

  const validateInput = () => {
    if (!title) {
      showToast("Judul harus diisi");
      if (title.length > 30) {
        showToast("Maksimal judul 30 karakter");
      }
      return false;
    }

    if (!image) {
      showToast("Cover Thumbnail harus diisi");
      return false;
    }

    if (!description) {
      showToast("Informasi Umum harus diisi");
      return false;
    }

    if (!organizer) {
      showToast("Penyelenggara harus diisi");
      return false;
    }

    if (type === "asynchronized") {
      if (category === null) {
        showToast("Tipe File harus dipilih");

        return false;
      }
    }

    if (type === "synchronized") {
      if (!linkStream) {
        showToast("Link Streaming harus diisi");

        return false;
      }
    }

    if (hasAssesment) {
      if (!titleAssesment) {
        showToast("Judul Tugas harus diisi");

        return false;
      }
      if (!filesAssesment.length) {
        showToast("Bahan Tugas  harus diisi");

        return false;
      }
    }

    if (hasQuiz) {
      if (!paketSoalId) {
        showToast("Paket Soal harus dipilih");
        return false;
      }
    }

    if (category?.value === "video") {
      if (!youtubeVideoSelected?.value) {
        showToast("Video harus dipilih");

        return false;
      }
    }

    if (category?.value === "pdf") {
      if (!pdf) {
        showToast("PDF harus diisi");

        return false;
      }
    }

    return true;
  };

  const createFormData = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("nama_penyelenggara", organizer);
    formData.append(
      "kategori_pengetahuan_id",
      kategoriPengetahuanId ? kategoriPengetahuanId.value : ""
    );
    formData.append("description", description);
    formData.append("status_publish", publish ? "1" : "0");
    formData.append("jenis_pengetahuan", type);

    if (type === "synchronized") {
      formData.append("jadwal_mulai", startDate);
      formData.append("jadwal_selesai", endDate);
      formData.append("link_streaming", linkStream);
      formData.append("category", "streaming");
    }
    if (image instanceof File) {
      formData.append("thumbnail", image);
    }

    if (category) {
      formData.append("category", category.value);
      if (youtubeVideoSelected && category.value === "video") {
        formData.append("path_file", youtubeVideoSelected.value);
      }
      if (pdf && category.value === "pdf") {
        if (pdf instanceof File) {
          formData.append("path_file", pdf);
        }
      }
    }

    if (hasQuiz) {
      formData.append("paket_soal_id", paketSoalId.toString());
      formData.append("durasi_quiz", duration.toString());
    }

    if (hasAssesment) {
      formData.append("judul_asesmen", titleAssesment);
      formData.append("keterangan_asesmen", descriptionAssesment);
      filesAssesment.forEach((file, index) => {
        if (file.url) {
          formData.append(`path_file_assessment[${index}]`, file.url);
        } else {
          formData.append(`path_file_assessment[${index}]`, file as File);
        }
      });
    }

    return formData;
  };

  const postData = async () => {
    if (!validateInput()) {
      return;
    }

    setManajemenPengetahuanState("loadingAction", true);

    const formData = createFormData();

    try {
      const idParams = mode === "edit" ? "/" + itemId : "";
      const response = await axiosInstance.post(`${url}${idParams}`, formData);
      clearState();
      setManajemenPengetahuanState("loadingAction", false);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      setManajemenPengetahuanState("mode", "");
      fetchData();

      return response.data;
    } catch (error) {
      setManajemenPengetahuanState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      if (responseData.code === 422) {
        Object.keys(responseData.errors).forEach((key) => {
          responseData.errors[key].forEach((errorMessage) => {
            toast({
              title: titleMessage,
              description: errorMessage,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          });
        });
      }

      toast({
        title: "Terjadi kesalahan saat menyimpan data",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      throw error;
    }
  };

  const handleEdit = async (id) => {
    const dataEdit = listData.find((item) => item.id === id);

    if (dataEdit) {
      const categorytSelected = listCategory.find(
        (item) => item.value === dataEdit?.category
      );

      const kategoriPengetahuanIdSelected = listKategoriPengetahuan.find(
        (item) => item.value === dataEdit?.kategori_pengetahuan_id
      );

      setManajemenPengetahuanState("title", dataEdit?.title);
      setManajemenPengetahuanState(
        "kategoriPengetahuanId",
        kategoriPengetahuanIdSelected
      );
      setManajemenPengetahuanState("category", categorytSelected);
      setManajemenPengetahuanState("description", dataEdit?.description);
      setManajemenPengetahuanState("image", dataEdit?.thumbnail);

      setManajemenPengetahuanState(
        "publish",
        dataEdit?.status_publish === 0 ? false : true
      );

      setManajemenPengetahuanState("organizer", dataEdit?.nama_penyelenggara);
      setManajemenPengetahuanState("startDate", dataEdit?.jadwal_mulai);
      setManajemenPengetahuanState("endDate", dataEdit?.jadwal_selesai);
      setManajemenPengetahuanState("linkStream", dataEdit?.link_streaming);
      setManajemenPengetahuanState("type", dataEdit?.jenis_pengetahuan);
      if (categorytSelected?.value === "video") {
        setManajemenPengetahuanState(
          "youtubeVideoSelected",
          dataEdit?.path_file
        );
      }

      if (categorytSelected?.value === "pdf") {
        setManajemenPengetahuanState("pdf", dataEdit?.path_file);
      }

      if (dataEdit?.paket_soal_id) {
        setManajemenPengetahuanState("hasQuiz", true);
        setManajemenPengetahuanState("paketSoalId", dataEdit?.paket_soal_id);
        setManajemenPengetahuanState("duration", dataEdit?.durasi_quiz);
      }

      if (dataEdit?.has_assessment) {
        setManajemenPengetahuanState("hasAssesment", true);
        setManajemenPengetahuanState(
          "titleAssesment",
          dataEdit?.assessment?.judul
        );
        setManajemenPengetahuanState(
          "descriptionAssesment",
          dataEdit?.assessment?.keterangan
        );

        const updateFilesAssesment = dataEdit?.assessment?.assessment_files.map(
          (item) => {
            return {
              url: item.path_file,
              name: item.nama_file,
              type: item.mime_type,
            };
          }
        );

        setManajemenPengetahuanState("filesAssesment", updateFilesAssesment);
      }

      setManajemenPengetahuanState("itemId", id);
      setManajemenPengetahuanState("mode", "edit");
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const clearState = () => {
    setManajemenPengetahuanState("title", "");
    setManajemenPengetahuanState("kategoriPengetahuanId", "");
    setManajemenPengetahuanState("description", "");
    setManajemenPengetahuanState("publish", false);
    setManajemenPengetahuanState("video", null);
    setManajemenPengetahuanState("pdf", null);
    setManajemenPengetahuanState("image", null);
    setManajemenPengetahuanState("youtubeVideoSelected", null);
    setManajemenPengetahuanState("category", null);
    setManajemenPengetahuanState("organizer", "");
    setManajemenPengetahuanState("type", "asynchronized");
    setManajemenPengetahuanState("linkStream", "");
    setManajemenPengetahuanState("startDate", "");
    setManajemenPengetahuanState("endDate", "");
    setManajemenPengetahuanState("paketSoalId", 0);
    setManajemenPengetahuanState("duration", 0);
    setManajemenPengetahuanState("hasQuiz", false);
    setManajemenPengetahuanState("hasAssesment", false);
    setManajemenPengetahuanState("titleAssesment", "");
    setManajemenPengetahuanState("descriptionAssesment", "");
    setManajemenPengetahuanState("filesAssesment", []);
  };

  return (
    <ManajemenPengetahuanProvider
      value={{
        state: state.state,
        setManajemenPengetahuanState,
        fetchData,
        postData,
        handleEdit,
        handleDelete,
        clearState,
        getListKategoriPengetahuan,
      }}
    >
      {children}
    </ManajemenPengetahuanProvider>
  );
};

export const useManajemenPengetahuanContext = () => useContext(Context);

const ManajemenPengetahuanContext = {
  useManajemenPengetahuanContext,
  Provider,
};

export default ManajemenPengetahuanContext;
