import React, { useEffect } from "react";
import { useRegisterContext } from "../../controller/auth/register";
import If, { Else } from "if-else-react";
import ReCAPTCHA from "react-google-recaptcha";
import env from "@ludovicm67/react-dotenv";
import {
  Box,
  Grid,
  Text,
  Select,
  Button,
  Input,
  Icon,
  Image,
  Flex,
  GridItem,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash, FaSpinner, FaArrowUp } from "react-icons/fa";
import ModalUploadImage from "./ModalUploadImage";
import { resizeImage } from "../../../misc/ImageHelper";

const ModalUpload = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Button
        leftIcon={<FaArrowUp />}
        bg="gray.500"
        color="white"
        _hover={{ bg: "gray.400" }}
        _active={{ bg: "gray.400" }}
        onClick={onOpen}
      >
        Unggah Foto
      </Button>
      <ModalUploadImage isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

const RegisterForm: React.FC = () => {
  const {
    handleDoRegister,
    changeNip,
    changePassword,
    changeConfirmPassword,
    checkNip,
    checkBox,
    changeNik,
    changeName,
    changeEmail,
    changeJenis,
    handleUploadImage,
  } = useRegisterContext();

  const {
    isLoading,
    isLoadingCheckNip,
    imagePhoto,
    jenis,
    name,
    email,
    errorMessage,
    nip,
    msgError,
    nik,
  } = useRegisterContext().state;

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    React.useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleShowPasswordConfirmation = (e) => {
    e.preventDefault();
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const cropImage = async (imageUrl) => {
    fetch(imageUrl)
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType) {
          const [typeWithoutCharset] = contentType.split(";");
          const fileType = typeWithoutCharset.trim();

          // Dapatkan informasi nama file dari URL
          const urlParts = imageUrl.split("/");
          const fileNameWithExtension = urlParts[urlParts.length - 1];
          const [fileName, fileExtension] = fileNameWithExtension.split(".");

          // Membuat objek File dari blob dengan nama dan tipe yang dinamis
          return response.blob().then((blob) => {
            const file = new File(
              [blob],
              `${fileName}_${Date.now()}.${fileExtension}`,
              { type: fileType }
            );

            // Mengubah ukuran gambar
            resizeImage(file).then((newFile) => {
              handleUploadImage(newFile);
            });
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const autoFill = searchParams.get("autoFill") ?? false;

    if (autoFill) {
      const name = searchParams.get("name") ?? "";
      const nip = searchParams.get("nip") ?? "";
      const nik = searchParams.get("nik") ?? "";
      const email = searchParams.get("email") ?? "";
      const pathFoto = searchParams.get("pathFoto") ?? "";
      const jenisUser = searchParams.get("jenisUser") ?? 0;

      changeJenis(jenisUser);
      changeNik(nik);
      changeNip(nip);
      changeName(name);
      changeEmail(email);
      cropImage(pathFoto);
    }
  }, []);

  return (
    <Box
      maxW={{ base: "2xl", lg: "7xl" }}
      mx="auto"
      px={{ lg: 7 }}
      pt={4}
      pb={18}
      w="full"
      textAlign="center"
      my={5}
    >
      <Box as="h1" fontSize={{ base: "2xl", md: "3xl" }} fontWeight="semibold">
        Pendaftaran Akun PINTAR
      </Box>
      {/* Form Login */}
      <Box w={{ base: "85%", md: "66.67%" }} mx="auto" mt="8">
        <Box bg="white" rounded="lg" overflow="hidden" mb={10}>
          {/* Error Message */}
          {errorMessage.length > 0 || msgError !== "" ? (
            <Box
              p={4}
              mb={4}
              fontSize="sm"
              color="red.700"
              bg="red.100"
              rounded="lg"
              m={5}
              textAlign="left"
              role="alert"
            >
              <ul className="list-disc px-5">
                {errorMessage.map((val) =>
                  Object.keys(val).map((u) => {
                    return <li>{val[u]}</li>;
                  })
                )}
                {/* message error */}
                {msgError !== "" && <li>{msgError}</li>}
              </ul>
            </Box>
          ) : null}

          <Box p={{ base: 4, sm: 6, md: 8 }} textAlign="center" mx="auto">
            {/* Form */}
            <Grid rowGap={4} gridTemplateColumns="auto">
              <Grid gridTemplateColumns="repeat(10, 1fr)" alignItems="center">
                <Text
                  gridColumn={{ base: "span 10", md: "span 2" }}
                  fontSize="sm"
                  textAlign="left"
                >
                  Jenis User
                </Text>
                <Box gridColumn={{ base: "span 10", md: "span 8" }}>
                  <Select
                    w="full"
                    h="10"
                    fontSize="sm"
                    placeholder="Pilih Jenis User"
                    borderColor="gray.300"
                    borderRadius="lg"
                    onChange={(e) => changeJenis(e.target.value)}
                    value={jenis ?? ""}
                    _focus={{
                      ring: "1",
                      ringColor: "green.600",
                      borderColor: "green.600",
                    }}
                    _placeholder={{ color: "gray.300" }}
                  >
                    <option value="1">ASN Kementerian Agama</option>
                    <option value="2">Non ASN Kementerian Agama</option>
                  </Select>
                </Box>
              </Grid>
              <Grid gridTemplateColumns="repeat(10, 1fr)" alignItems="center">
                <GridItem colSpan={{ md: 2, base: 10 }}>
                  <Text className="text-sm" fontSize="sm" textAlign="left">
                    Foto Profil
                  </Text>
                </GridItem>
                <GridItem colSpan={{ md: 1, base: 2 }}>
                  <Image
                    src={`${
                      imagePhoto == null
                        ? "https://ui-avatars.com/api/?background=007E39&color=fff&name=Testing"
                        : imagePhoto
                    }`}
                    alt="image_foto_profil"
                    gridColumn={{ md: "span 1", base: "span 5" }}
                    rounded="full"
                    w={16}
                    h={16}
                  />
                </GridItem>
                <GridItem colSpan={{ md: 2, base: 6 }}>
                  <ModalUpload />
                </GridItem>
                <GridItem colSpan={{ md: 3, base: 10 }}>
                  <Text
                    textAlign="left"
                    fontSize={{ base: "xs", md: "sm" }}
                    ml={{ base: 0, md: 2 }}
                    mt={{ base: 2, md: 0 }}
                  >
                    Foto profil kamu disarankan <br /> memiliki rasio 4 : 6{" "}
                  </Text>
                </GridItem>
              </Grid>
              {jenis.toString() === "1" ? (
                <Grid templateColumns="repeat(10, 1fr)" alignItems="center">
                  <Text
                    gridColumn={{ base: "span 10", md: "span 2" }}
                    fontSize="sm"
                    textAlign="left"
                  >
                    NIP
                  </Text>
                  <Box gridColumn={{ base: "span 10", md: "span 8" }}>
                    <Box position="relative" color="gray.700">
                      <Input
                        width="full"
                        height="10"
                        pl={3}
                        pr={8}
                        fontSize="sm"
                        placeholder="Masukkan NIP dan Cari"
                        type="text"
                        onChange={(e) => changeNip(e.target.value)}
                        value={nip}
                        borderColor="gray.300"
                        rounded="lg"
                        _focus={{
                          borderColor: "green.600",
                          ringColor: "green.600",
                          ring: "1",
                        }}
                        _placeholder={{ color: "gray.300" }}
                      />

                      <Button
                        position="absolute"
                        top="0"
                        right="0"
                        h="full"
                        display="flex"
                        alignItems="center"
                        px={7}
                        color="white"
                        bg="green.600"
                        roundedRight="lg"
                        _hover={{ bg: "green.700" }}
                        _focus={{ bg: "green.700" }}
                        onClick={() => checkNip()}
                        disabled={isLoadingCheckNip}
                      >
                        <If condition={isLoadingCheckNip}>
                          <svg
                            role="status"
                            className="inline mr-3 w-4 h-4 text-white animate-spin"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                          Loading...
                          <Else />
                          Cari
                        </If>
                      </Button>
                    </Box>
                    <Text
                      fontSize="xs"
                      fontStyle="italic"
                      color="gray.500"
                      mt={1}
                      textAlign="left"
                    >
                      *Data tersinkronisasi dengan SIMPEG. Mohon update pada
                      SIMPEG Anda
                    </Text>
                  </Box>
                </Grid>
              ) : jenis.toString() === "2" ? (
                <Grid templateColumns="repeat(10, 1fr)" alignItems="center">
                  <Text
                    gridColumn={{ base: "span 10", md: "span 2" }}
                    fontSize="sm"
                    textAlign="left"
                  >
                    NIK
                  </Text>
                  <Input
                    gridColumn={{ base: "span 10", md: "span 8" }}
                    width="full"
                    height="10"
                    pl={3}
                    pr={8}
                    fontSize="sm"
                    placeholder="Masukkan NIK Anda"
                    borderColor="gray.300"
                    rounded="lg"
                    _focus={{
                      borderColor: "green.600",
                      ringColor: "green.600",
                      ring: "1",
                    }}
                    _placeholder={{ color: "gray.300" }}
                    value={nik}
                    onChange={(e) => changeNik(e.target.value)}
                  />
                </Grid>
              ) : null}
              <Grid templateColumns="repeat(10, 1fr)" alignItems="center">
                <Text
                  gridColumn={{ base: "span 10", md: "span 2" }}
                  fontSize="sm"
                  textAlign="left"
                >
                  Nama Lengkap
                </Text>
                <Input
                  gridColumn={{ base: "span 10", md: "span 8" }}
                  width="full"
                  height="10"
                  pl={3}
                  pr={8}
                  fontSize="sm"
                  placeholder="Masukkan Nama Anda"
                  borderColor="gray.300"
                  rounded="lg"
                  _focus={{
                    borderColor: "green.600",
                    ringColor: "green.600",
                    ring: "1",
                  }}
                  _placeholder={{ color: "gray.300" }}
                  defaultValue={name}
                  onChange={(e) => changeName(e.target.value)}
                />
              </Grid>
              <Grid templateColumns="repeat(10, 1fr)" alignItems="center">
                <Text
                  gridColumn={{ base: "span 10", md: "span 2" }}
                  fontSize="sm"
                  textAlign="left"
                >
                  Email
                </Text>
                <Box gridColumn={{ base: "span 10", md: "span 8" }}>
                  <Input
                    width="full"
                    height="10"
                    pl={3}
                    pr={8}
                    fontSize="sm"
                    placeholder="Masukkan Email Anda"
                    borderColor="gray.300"
                    rounded="lg"
                    _focus={{
                      borderColor: "green.600",
                      ringColor: "green.600",
                      ring: "1",
                    }}
                    style={{ fontSize: "14px" }}
                    defaultValue={email}
                    _placeholder={{ color: "gray.300" }}
                    onChange={(e) => changeEmail(e.target.value)}
                  />
                  <Text
                    fontSize="xs"
                    fontStyle="italic"
                    color="gray.500"
                    mt={1}
                    textAlign="left"
                  >
                    *pastikan email di atas adalah email aktif dan bisa
                    digunakan
                  </Text>
                </Box>
              </Grid>
              <Grid templateColumns="repeat(10, 1fr)" alignItems="center">
                <Text
                  gridColumn={{ base: "span 10", md: "span 2" }}
                  fontSize="sm"
                  textAlign="left"
                >
                  Password
                </Text>
                <Box
                  position="relative"
                  gridColumn={{ base: "span 10", md: "span 8" }}
                >
                  <Input
                    width="full"
                    height="10"
                    pl={3}
                    pr={showPassword ? 8 : "2.5rem"}
                    fontSize="sm"
                    placeholder="Masukkan Password"
                    borderColor="gray.300"
                    rounded="lg"
                    _focus={{
                      borderColor: "green.600",
                      ringColor: "green.600",
                      ring: "1",
                    }}
                    _placeholder={{ color: "gray.300" }}
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    onChange={(e) => changePassword(e.target.value)}
                  />
                  <Box
                    position="absolute"
                    top="50%"
                    transform="translateY(-50%)"
                    right="0.75rem"
                    cursor="pointer"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Box>
                </Box>
              </Grid>
              <Grid templateColumns="repeat(10, 1fr)" alignItems="center">
                <Text
                  gridColumn={{ base: "span 10", md: "span 2" }}
                  fontSize="sm"
                  textAlign="left"
                >
                  Konfirmasi Password
                </Text>
                <Box
                  position="relative"
                  gridColumn={{ base: "span 10", md: "span 8" }}
                >
                  <Input
                    width="full"
                    height="10"
                    pl={3}
                    pr={showPasswordConfirmation ? 8 : "2.5rem"}
                    fontSize="sm"
                    placeholder="Konfirmasi Password"
                    borderColor="gray.300"
                    rounded="lg"
                    _focus={{
                      borderColor: "green.600",
                      ringColor: "green.600",
                      ring: "1",
                    }}
                    _placeholder={{ color: "gray.300" }}
                    type={showPasswordConfirmation ? "text" : "password"}
                    onChange={(e) => changeConfirmPassword(e.target.value)}
                  />
                  <Box
                    position="absolute"
                    top="50%"
                    transform="translateY(-50%)"
                    right="0.75rem"
                    cursor="pointer"
                    onClick={handleShowPasswordConfirmation}
                  >
                    {showPasswordConfirmation ? <FaEyeSlash /> : <FaEye />}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* /Form */}

            {/* Divider */}
            <Box borderBottomWidth={2} borderStyle="dashed" mt={4}></Box>
            {/* /Divider */}

            {/* Footer Sign In */}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              mt={5}
              flexDirection={{ base: "column", md: "row" }}
            >
              <ReCAPTCHA
                sitekey={env.REACT_APP_SITE_KEY_GOOGLE_RECAPTCHA}
                onChange={checkBox}
              />

              <Button
                border="none"
                fontSize="sm"
                fontWeight="medium"
                rounded="md"
                color="white"
                bg="green.600"
                mt={{ base: "4", md: 0 }}
                _hover={{ bg: "green.700" }}
                onClick={() => handleDoRegister()}
              >
                <If condition={isLoading}>
                  <Icon
                    as={FaSpinner}
                    display="inline"
                    mr={3}
                    w={4}
                    h={4}
                    color="white"
                    animation="spin infinite 1s linear"
                  />
                  Loading...
                  <Else />
                  Daftar
                </If>
              </Button>
            </Flex>
            {/* /Footer Sign In */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterForm;
