import { injectable } from "tsyringe";
import { ModulPelatihanApiRepository } from "../../app/repository/api/ModulPelatihanApiRepository";
import { ModulPelatihan, SubModulPelatihan } from "../../entities/ModulPelatihan";

@injectable()
export class ModulPelatihanPresenter {
  private repository: ModulPelatihanApiRepository;
  constructor(repository: ModulPelatihanApiRepository) {
    this.repository = repository;
  }

  public getAllModulPelatihan(
    page: number,
    rows: number , 
    keyword : string,
    pelatihan_id : number
  ): Promise<ModulPelatihan[]> {
    return this.repository.getAllModulPelatihan(page,rows,keyword,pelatihan_id);
  }

  public getDetailModulPelatihan(
    id: number
  ): Promise<ModulPelatihan> {
    return this.repository.getDetailModulPelatihan(id);
  }


  public storeModulPelatihan(
    pelatihan_id: number , 
    judul_modul : string ,
    sequence : number
    ): Promise<any> {
    return this.repository.storeModulPelatihan(
        pelatihan_id, 
        judul_modul ,
        sequence
    );
  }

  public updateModulPelatihan(
    id : number,
    judul_modul: string,
    sequence : number
    ): Promise<any> {
    return this.repository.updateModulPelatihan(
        id,
        judul_modul,
        sequence
    );
  }

  public deleteModulPelatihan(id: number): Promise<any> {
    return this.repository.deleteModulPelatihan(id);
  }

  public getAllSubModulPelatihan(
    page: number,
    rows: number , 
    keyword : string
  ): Promise<SubModulPelatihan[]> {
    return this.repository.getAllSubModulPelatihan(page,rows,keyword);
  }

  public getDetailSubModulPelatihan(
    id: number
  ): Promise<SubModulPelatihan> {
    return this.repository.getDetailSubModulPelatihan(id);
  }


  public storeSubModulPelatihan(
    jenis_form_section : string,
    modul_pelatihan_id: number , 
    jenis_sub_modul : string , 
    materi_id: any , 
    jenis_ujian : string ,
    paket_soal_id: number , 
    judul : string ,
    durasi_latihan : number,
    passing_grade : number,
    sequence : number
    ): Promise<any> {
    return this.repository.storeSubModulPelatihan(
        jenis_form_section,
        modul_pelatihan_id, 
        jenis_sub_modul, 
        materi_id, 
        jenis_ujian,
        paket_soal_id, 
        judul,
        durasi_latihan,
        passing_grade,
        sequence
    );
  }

  public updateSubModulPelatihan(
    jenis_form_section : string,
    id : number,
    modul_pelatihan_id: number , 
    jenis_sub_modul : string , 
    materi_id: any , 
    jenis_ujian : string ,
    paket_soal_id: number , 
    judul : string ,
    ada_latihan : boolean,
    durasi_latihan : number,
    passing_grade : number,
    sequence : number
    ): Promise<any> {
    return this.repository.updateSubModulPelatihan(
        jenis_form_section,
        id,
        modul_pelatihan_id, 
        jenis_sub_modul, 
        materi_id, 
        jenis_ujian,
        paket_soal_id, 
        judul,
        ada_latihan,
        durasi_latihan,
        passing_grade,
        sequence
    );
  }

  public deleteSubModulPelatihan(id: number): Promise<any> {
    return this.repository.deleteSubModulPelatihan(id);
  }

  public bobotPenilaianSubModulPelatihan(sub_modul_id : number , bobot_penilaian : number): Promise<any> {
    return this.repository.bobotPenilaianSubModulPelatihan(sub_modul_id , bobot_penilaian);
  }

  
}
