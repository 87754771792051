import React from "react";
import { Box, Text, Link, Flex, Icon, Button } from "@chakra-ui/react";
import { useIsAuthenticated } from "react-auth-kit";
import { If, Then, Else } from "react-if";
import { parseISO, isBefore, isAfter, parse, format } from "date-fns";
import { AiOutlineStar, AiOutlineCalendar, FiUsers } from "react-icons/all";
import { id } from "date-fns/locale";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import { usePelatihanSayaContext } from "../../../controller/registered/pelatihan_saya";

/**
 * Component for displaying training cards.
 * @component
 * @param {Object} props - Component properties.
 * @param {Pelatihan} props.pelatihan - Training object to be displayed.
 * @returns {JSX.Element} - JSX for displaying the training card.
 * @author Bagas Prasetyadi
 */

const CardItemPelatihan = ({ pelatihan }: { pelatihan: Pelatihan }) => {
  // Call Controller
  const { storePelatihanSaya } = usePelatihanSayaContext();
  const { currentTime, isLoading } = usePelatihanSayaContext().state;

  const currentDate = currentTime;
  const location = window.location.pathname;

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  const isTrainingCompleted = (trainingEndDate) => {
    // Convert the training completion date to a Date object
    const endDate = parseISO(trainingEndDate);

    // Get the current date
    const now = new Date();

    // Checks whether the training end date is past today's date
    return isBefore(endDate, now);
  };

  const parseDate = (dateString) => {
    return parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
  };

  const parsedWaktuMulai = parseDate(pelatihan.waktu_mulai);
  const parsedWaktuSelesai = parseDate(pelatihan.waktu_selesai);

  return (
    <Box
      maxW={{ base: "full", md: "400px" }}
      className="group"
      mr={location === "/" ? 8 : 0}
    >
      <Box
        bg="white"
        rounded="lg"
        overflow="hidden"
        shadow="sm"
        transition="all"
        transitionDuration="500ms"
        _hover={{ shadow: "lg" }}
      >
        <Box
          w="full"
          position="relative"
          maxH="200px"
          backgroundImage={`url(${pelatihan.path_thumbnail})`}
          backgroundSize="cover"
          style={{ aspectRatio: "2/1" }}
        >
          {isTrainingCompleted(pelatihan.waktu_selesai) && (
            <Box
              position="absolute"
              top={0}
              left={0}
              w="full"
              h="full"
              bg="rgba(169, 169, 169, 0.5)"
            />
          )}
          <Text
            position="absolute"
            top={0}
            right={0}
            mt={2}
            mr={2}
            rounded="xl"
            py={1}
            px={4}
            fontSize="sm"
            bg="Cream"
          >
            Angkatan {pelatihan.angkatan}
          </Text>
          {isTrainingCompleted(pelatihan.waktu_selesai) && (
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform={`translate(-50%, -50%) rotate(-6deg)`}
              border="2px"
              borderColor="red.600"
              py={1}
              px={2}
              w="70%"
              textAlign="center"
            >
              <Text color="red.600" fontSize="3xl" fontWeight="semibold">
                {isTrainingCompleted(pelatihan.waktu_selesai) ? "SELESAI" : ""}
              </Text>
            </Box>
          )}
        </Box>

        <Box p={6}>
          <Box h={52}>
            {/* Judul */}
            <If
              condition={
                isAuthenticated() !== false &&
                pelatihan.status_pelatihan === "Sedang Mengikuti"
              }
            >
              <Then>
                <If
                  condition={
                    isAfter(parseISO(currentDate), parsedWaktuMulai) &&
                    isBefore(parseISO(currentDate), parsedWaktuSelesai)
                  }
                >
                  <Then>
                    <Link
                      href={`/detail_pelatihan_saya/${pelatihan.id}`}
                      fontSize="lg"
                      fontWeight="semibold"
                    >
                      {pelatihan.judul_pelatihan.length > 39
                        ? pelatihan.judul_pelatihan.substring(0, 39) + "..."
                        : pelatihan.judul_pelatihan}
                    </Link>
                  </Then>
                  <Else>
                    <Link
                      href={`/detail/pelatihan/${pelatihan.id}`}
                      fontSize="lg"
                      fontWeight="semibold"
                    >
                      {pelatihan.judul_pelatihan.length > 39
                        ? pelatihan.judul_pelatihan.substring(0, 39) + "..."
                        : pelatihan.judul_pelatihan}
                    </Link>
                  </Else>
                </If>
              </Then>
              <Else>
                <Link
                  href={`/detail/pelatihan/${pelatihan.id}`}
                  fontSize="lg"
                  fontWeight="semibold"
                >
                  {pelatihan.judul_pelatihan.length > 39
                    ? pelatihan.judul_pelatihan.substring(0, 39) + "..."
                    : pelatihan.judul_pelatihan}
                </Link>
              </Else>
            </If>

            {/* Tanggal Dimulai */}
            <Flex mt={3}>
              <Icon as={AiOutlineCalendar} color="#B99B6B" boxSize={5} mr={3} />
              <Text fontSize="sm">
                {format(parsedWaktuMulai, "dd MMM yyyy", {
                  locale: id,
                })}{" "}
                - {format(parsedWaktuSelesai, "dd MMM yyyy", { locale: id })}
              </Text>
            </Flex>

            <Flex mt={3}>
              <Icon as={FiUsers} color="#B99B6B" boxSize={5} mr={3} />
              <Text fontSize="sm">Peserta {pelatihan.count_peserta}</Text>
            </Flex>

            {/* Rating Pelatihan */}
            <Flex mt={3}>
              <Icon as={AiOutlineStar} color="#B99B6B" boxSize={5} mr={3} />
              <Text fontSize="sm">{pelatihan.rating}</Text>
            </Flex>
          </Box>

          <Box mx="auto" position="relative" className="btn-pelatihan">
            <Box position="absolute" insetX={0} bottom={0}>
              <If
                condition={
                  isAuthenticated() !== false &&
                  pelatihan.status_pelatihan === "Sedang Mengikuti"
                }
              >
                <Then>
                  <If
                    condition={
                      isAfter(parseISO(currentDate), parsedWaktuMulai) &&
                      isBefore(parseISO(currentDate), parsedWaktuSelesai)
                    }
                  >
                    <Then>
                      <Button
                        as="a"
                        href={`/detail_pelatihan_saya/${pelatihan.id}`}
                        w="full"
                        color="white"
                        rounded="md"
                        bg="green.700"
                        _hover={{ bg: "green.800" }}
                        _active={{ bg: "green.800" }}
                      >
                        Lanjutkan Pelatihan
                      </Button>
                    </Then>
                    <Else>
                      <Button
                        as="a"
                        href={`/detail/pelatihan/${pelatihan.id}`}
                        w="full"
                        color="white"
                        rounded="md"
                        bg="green.700"
                        _hover={{ bg: "green.800" }}
                        _active={{ bg: "green.800" }}
                      >
                        Lihat Selengkapnya
                      </Button>
                    </Else>
                  </If>
                </Then>
                <Else>
                  <Button
                    as="a"
                    href={`/detail/pelatihan/${pelatihan.id}`}
                    w="full"
                    color="white"
                    rounded="md"
                    bg="green.700"
                    _hover={{ bg: "green.800" }}
                    _active={{ bg: "green.800" }}
                  >
                    Lihat Selengkapnya
                  </Button>
                </Else>
              </If>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardItemPelatihan;
