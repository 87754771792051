/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import If, { Else } from "if-else-react";
import moment from "moment/moment";
import ProgressBar from "@ramonak/react-progress-bar";
import Skeleton from "react-loading-skeleton";
import { ToastContainer } from "react-toastify";
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useBreakpointValue,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { FaSearch, FaStar } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { Wrapper } from "../../../components/Wrapper";
import PelatihanController, {
  usePelatihanContext,
} from "../../../controller/pelatihan/index";
import PelatihanSayaController, {
  usePelatihanSayaContext,
} from "../../../controller/registered/pelatihan_saya";
import SertifikatController, {
  useSertifikatContext,
} from "../../../controller/sertifikat/index";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import { PelatihanSaya } from "../../../../../entities/PelatihanSaya";
import { GetAuthData } from "../../../../misc/GetAuthData";
import { useIsAuthenticated } from "react-auth-kit";
import { GetLocalStorage } from "../../../../misc/UseLocalStorage";
import Hero from "../../../assets/svg/bg_landing_page.svg";
import HeroMobile from "../../../assets/svg/bg_landing_page_mobile.svg";
import { ReactComponent as LogoPintar } from "../../../assets/svg/pintar_white.svg";
import { Placement } from "react-joyride";
import JoyrideComponent from "../../../components/global/Joyride";
import CardItemPelatihan from "../../../components/global/Card/CardItemPelatihan";
import PengetahuanController, {
  usePengetahuanContext,
} from "../../../controller/pengetahuan/index";
import DataTable from "react-data-table-component";
import { parseISO, format } from "date-fns";
import { id } from "date-fns/locale";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

const TrainingSection: React.FC = () => {
  const { pelatihan, isLoading } = usePelatihanContext().state;

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  return (
    <div
      className={isAuthenticated() !== false ? "" : "bg-gray-100"}
      style={{ borderTopLeftRadius: "40px", borderTopRightRadius: "40px" }}
    >
      <div className="max-w-2xl mx-auto lg:max-w-7xl px-8 pt-20 pb-20 w-full">
        <div className="flex justify-between items-center w-full">
          <h2
            className="font-semibold tracking-tight text-2xl"
            style={{ color: "#3C4952" }}
          >
            Pelatihan Terbaru
          </h2>

          <a
            href="/pelatihan"
            className="text-lg font-semibold text-green-600 hover:text-green-600"
          >
            Lihat Semua
          </a>
        </div>
        <div className="mt-5">
          <If condition={isLoading}>
            <Skeleton
              count={4}
              style={{ height: "400px", width: "280px", marginRight: "20px" }}
              inline={true}
            />
            <Else />
            <If condition={pelatihan.length > 0}>
              <Carousel responsive={responsive} ssr={true}>
                {pelatihan.map((v: Pelatihan) => (
                  <CardItemPelatihan pelatihan={v} key={v.id} />
                ))}
              </Carousel>
              <Else />
              <div className="mx-auto text-center mt-20 mb-20">
                <h1 className="text-xl text-gray-400">
                  Belum Ada Pelatihan Terbaru
                </h1>
              </div>
            </If>
          </If>
        </div>
      </div>
    </div>
  );
};

// After Login Page
const HeroMainAfterLogin: React.FC = () => {
  return (
    <Box
      backgroundImage={{ base: `url(${HeroMobile})`, md: `url(${Hero})` }}
      backgroundSize="cover"
      h={{ base: "200px", sm: "330px", md: "42vh" }}
    >
      <Box py={{ base: 8, md: 16 }} px={{ base: 8, md: 20 }}>
        <Icon
          as={LogoPintar}
          w={{ base: 20, md: 24 }}
          h={{ base: 8, md: 16 }}
        />
        <Text
          fontWeight="semibold"
          fontSize={{ base: "2xl", md: "5xl" }}
          color="white"
          mt={8}
        >
          Selamat Datang
        </Text>
        <Text
          fontWeight="medium"
          fontSize={{ base: "xl", md: "4xl" }}
          color="white"
        >
          {GetAuthData()?.username}
        </Text>
      </Box>
    </Box>
  );
};

const ModalSertifikat = () => {
  // Call Controller Sertifikat
  const { linkPDF, isLoadingDownload, isLoadingGet, showModal } =
    useSertifikatContext().state;

  const { downloadSertifikat, setShowModal } = useSertifikatContext();

  const { pelatihanItem } = usePelatihanSayaContext().state;

  const handleDownloadSertifikat = () => {
    if (pelatihanItem) {
      downloadSertifikat(
        pelatihanItem?.pelatihan_id,
        pelatihanItem?.judul_pelatihan
      );
    }
  };

  return (
    <>
      {showModal ? (
        <>
          {/* background modal */}
          <div
            className="fixed top-0 left-0 right-0 w-full overflow-x-hidden overflow-y-auto inset-0 h-modal md:h-full justify-center items-center flex bg-gray-800 opacity-50"
            aria-modal="true"
            role="dialog"
            tabIndex={-1}
            style={{ zIndex: 999999 }}
          ></div>

          {/* Modal */}
          <div
            className="flex justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
            style={{ zIndex: 999999 }}
          >
            <div className="relative w-full h-full max-w-7xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Sertifikat Pelatihan
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => setShowModal(false)}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-5">
                  <If condition={isLoadingGet}>
                    <div className="text-center mx-20 my-20">
                      <svg
                        role="status"
                        className="inline w-20 h-20 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                    <Else />
                    <iframe
                      src={`${linkPDF}`}
                      width={"100%"}
                      height={"600"}
                      style={{ borderRadius: "6px" }}
                      title="pdf sertifikat"
                    />
                  </If>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b">
                  <button
                    type="button"
                    className="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"
                    onClick={handleDownloadSertifikat}
                  >
                    <If condition={isLoadingDownload}>
                      <div className="text-center">
                        <svg
                          role="status"
                          className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                      <Else />
                      Download
                    </If>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

// On Going Tab
const CardOnGoingProgressPelatihan = ({
  pelatihan,
  currentTime,
}: {
  pelatihan: PelatihanSaya;
  currentTime: string;
}) => {
  const currentDate = currentTime;

  return (
    <a
      href={
        moment(currentDate).isAfter(pelatihan.waktu_mulai) &&
        moment(currentDate).isBefore(pelatihan.waktu_selesai)
          ? `detail_pelatihan_saya/${pelatihan.pelatihan_id}`
          : `detail/pelatihan/${pelatihan.pelatihan_id}`
      }
    >
      <div
        className="bg-white rounded-lg overflow-hidden mb-10 hover:shadow-lg transition-all duration-500"
        style={{ height: "370px", width: "290px" }}
      >
        <div
          className="w-full"
          style={{
            height: "178px",
            backgroundImage: `url(${pelatihan.path_thumbnail})`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="p-5 sm:p-7 md:p-6 xl:p-7 -mt-2">
          <div className="h-40">
            {/* Judul */}
            <p className="text-lg font-semibold">
              {pelatihan.judul_pelatihan.length > 39
                ? pelatihan.judul_pelatihan.substring(0, 39) + "..."
                : pelatihan.judul_pelatihan}
            </p>

            {/* Penyelenggara */}
            <p className="text-sm text-gray-500 mt-2">
              {pelatihan.penyelenggara.length > 39
                ? pelatihan.penyelenggara.substring(0, 39) + "..."
                : pelatihan.penyelenggara}
            </p>
          </div>

          {/* Progress */}
          <div className="mx-auto relative">
            <div className="absolute inset-x-0 bottom-0">
              <If
                condition={
                  moment(currentDate).isAfter(pelatihan.waktu_mulai) &&
                  moment(currentDate).isBefore(pelatihan.waktu_selesai)
                }
              >
                <div className="grid grid-cols-5 gap-3 justify-between mt-10">
                  <div className="col-span-4">
                    <ProgressBar
                      completed={
                        pelatihan.modul_selesai != null
                          ? pelatihan.modul_selesai
                          : 0
                      }
                      maxCompleted={pelatihan.modul_belum}
                      className="w-full"
                      height="7px"
                      bgColor="#007E39"
                      customLabel=" "
                      animateOnRender={true}
                    />
                  </div>
                  <div className="col-span-1 text-right">
                    <p className="-mt-2 text-gray-400">
                      {pelatihan.modul_selesai != null
                        ? pelatihan.modul_selesai
                        : 0}
                      /{pelatihan.modul_belum}
                    </p>
                  </div>
                </div>
                <Else />
                <div className="mt-10">
                  <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                    Menunggu Jadwal Pelaksanaan
                  </span>
                </div>
              </If>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const ProgressPengetahuan = () => {
  const { listPengetahuanSaya, isLoadingPengetahuanSaya } =
    usePengetahuanContext().state;

  const history = useHistory();

  return (
    <>
      {isLoadingPengetahuanSaya ? (
        <SimpleGrid
          columns={{ sm: 1, md: 1, lg: 2 }}
          spacing={4}
          mt={6}
          bg="white"
          rounded="lg"
          p={4}
        >
          <Skeleton count={5} />
        </SimpleGrid>
      ) : listPengetahuanSaya.length ? (
        <Box mt={5} w="full">
          <Carousel responsive={responsive} ssr={true}>
            {listPengetahuanSaya.map((v, key) => (
              <Box
                key={v.id}
                bg="white"
                rounded="lg"
                overflow="hidden"
                mb={10}
                transition="all 0.5s"
                _hover={{ shadow: "lg", cursor: "pointer" }}
                style={{ height: "300px", width: "290px" }}
                onClick={() => {
                  history.push(`/detail/pengetahuan/${v.pengetahuan_id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Box
                  w="full"
                  h="178px"
                  backgroundImage={`url(${v.thumbnail})`}
                  backgroundSize="cover"
                ></Box>
                <Box p={{ base: 4, md: 6 }}>
                  <Text as="p" fontSize="lg" fontWeight="semibold">
                    {v.title.length > 39
                      ? v.title.substring(0, 39) + "..."
                      : v.title}
                  </Text>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      ) : (
        <div className="bg-white rounded-md w-full mt-6 py-20 px-10 text-center">
          <h1 className="text-xl text-gray-400">
            Belum ada pengetahuan yang diikuti
          </h1>
          <p className="text-gray-400 mb-4">
            Pilih pengetahuan Pintar yang diinginkan
          </p>
          <a
            href="/pengetahuan"
            className="text-white bg-yellow-400 hover:bg-yellow-400 focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 mt-5 focus:outline-none transition-all"
          >
            Pilih Pengetahuan
          </a>
        </div>
      )}
    </>
  );
};

const ContentOnGoing: React.FC = () => {
  const { isLoading, pelatihanSaya, currentTime } =
    usePelatihanSayaContext().state;

  const { getPengetahuanSaya, getJumlahBintang } = usePengetahuanContext();

  useEffect(() => {
    getPengetahuanSaya("mengikuti");
    getJumlahBintang();
  }, []);

  return (
    <div className="w-full">
      {/* Progress Pelatihan */}
      <div className="mb-20 mt-10">
        {/* Header */}
        <div className="flex justify-between items-center w-full">
          <h2
            className="font-semibold tracking-tight text-2xl"
            style={{ color: "#3C4952" }}
          >
            Progress Pelatihan
          </h2>
        </div>

        {/* Content */}
        <If condition={isLoading}>
          <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-6 bg-white rounded-lg">
            <Skeleton count={5} />
          </div>
          <Else />
          {pelatihanSaya.length > 0 ? (
            <Box mt={5}>
              <Carousel responsive={responsive} ssr={true}>
                {pelatihanSaya.map((v, key) => (
                  <CardOnGoingProgressPelatihan
                    pelatihan={v}
                    key={key}
                    currentTime={currentTime}
                  />
                ))}
              </Carousel>
            </Box>
          ) : (
            <div className="bg-white rounded-md w-full mt-6 py-20 px-10 text-center">
              <h1 className="text-xl text-gray-400">
                Belum ada pelatihan yang diikuti
              </h1>
              <p className="text-gray-400 mb-4">
                Pilih pelatihan Pintar yang diinginkan
              </p>
              <a
                href="/pelatihan"
                className="text-white bg-yellow-400 hover:bg-yellow-400 focus:ring-4 focus:ring-green-600 font-semibold rounded-lg text-sm px-5 py-2.5 mt-5 focus:outline-none transition-all"
              >
                Pilih Pelatihan
              </a>
            </div>
          )}
        </If>
      </div>

      {/* Progress Pengetahuan */}

      <div className="flex justify-between items-center w-full">
        <h2
          className="font-semibold tracking-tight text-2xl"
          style={{ color: "#3C4952" }}
        >
          Progress Pengetahuan
        </h2>
      </div>

      <ProgressPengetahuan />
    </div>
  );
};

// Done Tab
const CardDonePelatihan = ({ pelatihan }: { pelatihan: PelatihanSaya }) => {
  // Call Controller Sertifikat
  const { getSertifikat, setShowModal } = useSertifikatContext();

  const { setPelatihanItem } = usePelatihanSayaContext();

  const onmodalShow = () => {
    getSertifikat(pelatihan.pelatihan_id);
    setShowModal(true);
    setPelatihanItem(pelatihan);
  };

  return (
    <>
      <div style={{ width: "290px" }} className="group">
        <div className="bg-white rounded-lg overflow-hidden mb-10 group-hover:shadow-lg transition-all duration-500">
          <div
            className="w-full"
            style={{
              height: "178px",
              backgroundImage: `url(${pelatihan.path_thumbnail})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="p-5 sm:p-7 md:p-6 xl:p-7 -mt-2">
            {/* Judul */}
            <p className="text-lg font-semibold">
              {pelatihan?.judul_pelatihan.length > 39
                ? pelatihan?.judul_pelatihan.substring(0, 39) + "..."
                : pelatihan?.judul_pelatihan}
            </p>

            <div className="mt-7 mb-3 mx-auto">
              {/* Tinjau Ulang */}
              <a
                href={`/detail/pelatihan/${pelatihan.pelatihan_id}`}
                className="mx-auto py-3 block w-full text-center border border-transparent text-gray-500 font-medium rounded-md  bg-gray-200 hover:bg-gray-300 md:text-md transition-all duration-500"
              >
                Tinjau Ulang
              </a>

              <If
                condition={
                  pelatihan != null &&
                  pelatihan.predikat != null &&
                  !pelatihan.predikat.includes("Tidak")
                }
              >
                {/* Download Sertifikat */}
                <If condition={pelatihan.status_tte == "2"}>
                  <button
                    className="mx-auto py-3 block w-full text-center border border-transparent text-white mt-3 font-medium rounded-md  bg-green-600 hover:bg-green-600 md:text-md transition-all duration-500"
                    onClick={onmodalShow}
                  >
                    Download Sertifikat
                    <i className="fa-solid fa-download pl-3 -mt-1" />
                  </button>
                  <Else />
                  <button
                    className="mx-auto py-3 block w-full text-center border border-transparent  mt-3 font-medium rounded-md text-white bg-green-600 hover:bg-green-600 md:text-md transition-all duration-500"
                    disabled
                  >
                    Menunggu Proses TTE
                  </button>
                </If>
              </If>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TableDonePengetahuan = () => {
  const {
    listPengetahuanSaya,
    isLoadingPengetahuanSaya,
    totalPengetahuanSaya,
    pagePengetahuanSaya,
    perPagePengetahuanSaya,
  } = usePengetahuanContext().state;

  const { setState } = usePengetahuanContext();

  const history = useHistory();

  const handlePageChange = (page) => {
    setState((prevState) => ({ ...prevState, pagePengetahuanSaya: page }));
  };

  const handlePerRowsChange = (newPerPage) => {
    setState((prevState) => ({
      ...prevState,
      pagePengetahuanSaya: 1,
      perPagePengetahuanSaya: newPerPage,
    }));
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2FFFB",
        fontWeight: "900",
        fontSize: "13px",
      },
    },
  };

  const columns = [
    {
      name: "No.",
      selector: (row, index) =>
        (pagePengetahuanSaya - 1) * perPagePengetahuanSaya + (index + 1),
      width: "100px",
    },
    {
      name: "Nama Pengetahuan",
      selector: (row) => row?.title,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Mengikuti",
      selector: (row) =>
        row?.tanggal_mulai
          ? format(parseISO(row?.tanggal_mulai), "dd MMMM yyyy", { locale: id })
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Selesai Mengikuti",
      selector: (row) =>
        row?.tanggal_selesai
          ? format(parseISO(row?.tanggal_selesai), "dd MMMM yyyy", {
              locale: id,
            })
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Aksi",
      cell: (row) => {
        return (
          <Button
            color="white"
            bg="green.700"
            _hover={{ bg: "green.800" }}
            _active={{ bg: "green.800" }}
            onClick={() => {
              history.push(`/detail/pengetahuan/${row.pengetahuan_id}`);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Lihat Pengetahuan
          </Button>
        );
      },
      wrap: true,
    },
  ];

  return (
    <div>
      {listPengetahuanSaya.length ? (
        <DataTable
          columns={columns}
          data={listPengetahuanSaya}
          progressPending={isLoadingPengetahuanSaya}
          pagination
          paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
          paginationPerPage={perPagePengetahuanSaya}
          paginationServer
          paginationTotalRows={
            listPengetahuanSaya.length > 0 ? totalPengetahuanSaya : 0
          }
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          customStyles={customStyles}
          progressComponent={<Loader />}
        />
      ) : (
        <div className="bg-white rounded-md w-full mt-6 py-20 px-10 text-center">
          <h1 className="text-xl text-gray-400">
            Belum ada pengetahuan yang diselesaikan
          </h1>
        </div>
      )}
    </div>
  );
};

const ContentDone: React.FC = () => {
  // Call Controller
  const { pelatihanSayaDone, isLoading } = usePelatihanSayaContext().state;
  const { getPengetahuanSaya, downloadRaport } = usePengetahuanContext();
  const {
    pagePengetahuanSaya,
    perPagePengetahuanSaya,
    jumlahBintang,
    isLoadingDownloadRaport,
  } = usePengetahuanContext().state;

  useEffect(() => {
    getPengetahuanSaya("selesai");
  }, [pagePengetahuanSaya, perPagePengetahuanSaya]);
  return (
    <div className="w-full">
      {/* Progress Pelatihan */}
      <div className="mb-20 mt-10">
        {/* Header */}
        <div className="flex justify-between items-center w-full">
          <h2
            className="font-semibold tracking-tight text-2xl"
            style={{ color: "#3C4952" }}
          >
            Pelatihan Yang Sudah Selesai
          </h2>
        </div>

        {/* Content */}
        <If condition={isLoading}>
          <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-3 bg-white rounded-lg">
            <Skeleton count={5} />
          </div>
          <Else />

          {pelatihanSayaDone.length > 0 ? (
            <Box mt={5}>
              <Carousel responsive={responsive} ssr={true}>
                {pelatihanSayaDone.map((v, key) => (
                  <CardDonePelatihan pelatihan={v} key={key} />
                ))}
              </Carousel>
            </Box>
          ) : (
            <div className="bg-white rounded-md w-full mt-6 py-20 px-10 text-center">
              <h1 className="text-xl text-gray-400">
                Belum ada pelatihan yang diselesaikan
              </h1>
              <p className="text-gray-400 mt-2">
                Selesaikan pelatihan untuk mendapatkan sertifikat.
              </p>
            </div>
          )}
        </If>
      </div>

      <div>
        <div className="flex justify-between items-start md:items-center w-full mb-3 flex-col md:flex-row">
          <h2 className="font-semibold tracking-tight text-2xl text-[#3C4952] mb-2 md:mb-0">
            Pengetahuan Yang Sudah Selesai
          </h2>
          <div className="flex items-start md:items-center flex-col md:flex-row ">
            <div className="px-4 py-1 bg-white rounded-xl shadow-sm flex justify-center items-center mr-4 mb-2 md:mb-0">
              <span className="mr-2 text-yellow-400 text-xl">
                {" "}
                <FaStar />
              </span>
              <span className="font-semibold text-xl mr-2 text-black">
                {jumlahBintang}{" "}
              </span>
              <span className="text-base"> / Your Learning Point</span>
            </div>
            <Button
              bg="#FFEFD6"
              color="#F2994A"
              rightIcon={<FiDownload size={20} />}
              border="1px"
              borderColor="#F2994A"
              _active={{
                bg: "FFEFD6",
                color: "#F2994A",
              }}
              _hover={{
                bg: "FFEFD6",
                color: "#F2994A",
              }}
              isLoading={isLoadingDownloadRaport}
              onClick={() => downloadRaport()}
            >
              Rapot Pengetahuan
            </Button>
          </div>
        </div>

        <TableDonePengetahuan />
      </div>

      {/* ModalSertifikat */}
      <ModalSertifikat />
    </div>
  );
};

const NavsAfterLogin: React.FC = () => {
  const [tabSedangBerlangsung, setTabSedangBerlangsung] = React.useState(true);
  const [tabTelahSelesai, setTabTelahSelesai] = React.useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const steps = [
    {
      content: <p>Ingin melihat pelatihan yang aktif? Cek disini.</p>,
      placement: "bottom" as Placement,
      target: "#btn_sedang_berlangsung",
      disableBeacon: true,
    },
    {
      content: <p>Lihat daftar pelatihan yang telah selesai diikuti disini</p>,
      placement: "bottom" as Placement,
      target: "#btn_telah_selesai",
      disableBeacon: true,
    },
    {
      content: (
        <p style={{ textAlign: "start" }}>
          Lihat dan edit profile anda disini, data ini akan digunakan untuk data
          sertifikat. Pastikan anda mengisi dengan tepat
        </p>
      ),
      placement: "bottom" as Placement,
      target: ".user-profile",
      disableBeacon: true,
    },
  ];

  const setActiveTab = (e: any) => {
    const id = e.currentTarget.id;

    switch (id) {
      case "btn_sedang_berlangsung":
        setTabSedangBerlangsung(true);
        setTabTelahSelesai(false);
        break;

      case "btn_telah_selesai":
        setTabSedangBerlangsung(false);
        setTabTelahSelesai(true);
        break;

      default:
        break;
    }
  };

  const [keyword, setKeyword] = useState("");

  const searchPelatihan = (e) => {
    e.preventDefault();
    localStorage.setItem("keywordPelatihan", keyword);
    window.location.href = "/pelatihan";
  };

  return (
    <>
      <div className="bg-white flex justify-between items-center">
        <div className="max-w-2xl mx-auto lg:max-w-7xl px-8 w-full">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            w="full"
            my={{ base: 4, md: 8 }}
            flexDirection={{ base: "column", md: "row" }}
          >
            {/* Tabs */}
            <Box
              w={{ base: "full", md: "auto" }}
              display={{ base: "flex", md: "" }}
              justifyContent={{ base: "space-between", md: "" }}
            >
              <Button
                color={tabSedangBerlangsung ? "white" : "black"}
                bg={tabSedangBerlangsung ? "green.700" : ""}
                mr={{ base: 0, md: 4 }}
                _hover={{ bg: tabSedangBerlangsung ? "green.800" : "gray.200" }}
                onClick={(e) => setActiveTab(e)}
                id="btn_sedang_berlangsung"
              >
                Sedang Berlangsung
              </Button>
              <Button
                color={tabTelahSelesai ? "white" : "black"}
                bg={tabTelahSelesai ? "green.700" : ""}
                _hover={{ bg: tabTelahSelesai ? "green.800" : "gray.200" }}
                onClick={(e) => setActiveTab(e)}
                id="btn_telah_selesai"
              >
                Telah Selesai
              </Button>
            </Box>

            <Box
              w="full"
              display="flex"
              alignItems="center"
              justifyContent={{ base: "start", md: "end" }}
            >
              <form onSubmit={(e) => searchPelatihan(e)}>
                <InputGroup mt={{ base: 4, md: 0 }}>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FaSearch} color="gray.300" />
                  </InputLeftElement>
                  <Input
                    type="text"
                    placeholder="Cari Disini..."
                    mr={2}
                    borderColor="green.700"
                    _hover={{
                      borderColor: "green.700",
                    }}
                    _focus={{
                      ring: "1",
                      ringColor: "green.700",
                      borderColor: "green.700",
                    }}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <Button
                    color="green.700"
                    bg="transparent"
                    border="1px"
                    borderColor="green.700"
                    _hover={{ bg: "green.700", color: "white" }}
                    _focus={{ outline: "none" }}
                    type="submit"
                  >
                    Cari
                  </Button>
                </InputGroup>
              </form>
              {!isMobile && (
                <Box ml={12}>
                  <JoyrideComponent steps={steps} />
                </Box>
              )}
            </Box>
          </Box>

          {isMobile && (
            <Box mb={4}>
              <JoyrideComponent steps={steps} />
            </Box>
          )}
        </div>
      </div>

      {/* Content */}
      <div className="max-w-2xl mx-auto lg:max-w-7xl px-8 w-full mt-5 mb-5 transition-all duration-300">
        <If condition={tabSedangBerlangsung}>
          <ContentOnGoing />
          <Else />
          <ContentDone />
        </If>
      </div>

      {/* Training Section */}
      <TrainingSection />
    </>
  );
};

const WrapperMain: React.FC = () => {
  const { getPelatihan } = usePelatihanContext();

  const { getPelatihanSaya, getCurrentDateServer } = usePelatihanSayaContext();

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  const statusPengisian = GetLocalStorage({ name: "status_pengisian" });

  useEffect(() => {
    getCurrentDateServer().then(() => {
      getPelatihanSaya();
      getPelatihan(1, "");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if status pengisian is false, user will redirect to profile to complete profile
  if (isAuthenticated() !== false && statusPengisian !== "true") {
    window.location.href = "/profile";
  }

  return (
    <Wrapper>
      {/* Navbar White */}
      <HeroMainAfterLogin />

      {/* Tabs */}
      <NavsAfterLogin />
    </Wrapper>
  );
};

const PageMainAfterLogin: React.FC = () => {
  return (
    <PengetahuanController.Provider>
      <PelatihanController.Provider>
        <PelatihanSayaController.Provider>
          <SertifikatController.Provider>
            <ToastContainer
              position="top-center"
              style={{ width: "60%", marginTop: "5%" }}
            />
            <WrapperMain />
          </SertifikatController.Provider>
        </PelatihanSayaController.Provider>
      </PelatihanController.Provider>
    </PengetahuanController.Provider>
  );
};

export default PageMainAfterLogin;
