/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../../data/presenters/PelatihanPresenter";
import { toast } from "react-toastify";
import history from "../../../../../../history";
import moment from "moment";
import { JenisPelatihan } from "../../../../../../entities/JenisPelatihan";
import { JenisPelatihanPresenter } from "../../../../../../data/presenters/JenisPelatihanPresenter";
import { SubMenuJenisPelatihan } from "../../../../../../entities/MenuJenisPelatihan";
import { MenuJenisPelatihanPresenter } from "../../../../../../data/presenters/MenuJenisPelatihanPresenter";

interface IState {
  isLoading: boolean;
  path_thumbnail: any;
  jenis_pelatihan_id: number;
  sub_menu_jenis_pelatihan_id: number;
  waktu_mulai: string;
  waktu_selesai: string;
  waktu_mulai_pendaftaran: string;
  waktu_selesai_pendaftaran: string;
  penyelenggara: string;
  informasi_umum: string;
  kompetensi_teknis: string;
  persyaratan: string;
  angkatan: string;
  jenisPelatihan: JenisPelatihan[];
  subMenuJenisPelatihan: SubMenuJenisPelatihan[];
  msgError: any[];
  isPPKB: boolean;
  materiInti: string;
  eventId: string;
  isPretest: boolean;
  isForGuruMadrasah: boolean;
}

interface InitialState {
  state: IState;
  createPelatihan: Function;
  setPathThumbnail: Function;
  setJenisPelatihanId: Function;
  setSubMenuJenisPelatihanId: Function;
  setWaktuMulai: Function;
  setWaktuSelesai: Function;
  setWaktuMulaiPendaftaran: Function;
  setWaktuSelesaiPendaftaran: Function;
  setPenyelenggara: Function;
  setInformasiUmum: Function;
  setKompetensiTeknis: Function;
  setPersyaratan: Function;
  setAngkatan: Function;
  getJenisPelatihan: Function;
  getSubMenuJenisPelatihan: Function;
  setIsPPKB: Function;
  setCreateManajemenPelatihan: Function;
  setEventId: Function;
  setIsPretest: Function;
}

const initialState = {
  state: {
    isLoading: false,
    path_thumbnail: null,
    jenis_pelatihan_id: 0,
    sub_menu_jenis_pelatihan_id: 0,
    waktu_mulai: "",
    waktu_selesai: "",
    waktu_mulai_pendaftaran: "",
    waktu_selesai_pendaftaran: "",
    penyelenggara: "",
    informasi_umum: "",
    kompetensi_teknis: "",
    persyaratan: "",
    angkatan: "",
    jenisPelatihan: [],
    subMenuJenisPelatihan: [],
    msgError: [],
    isPPKB: false,
    materiInti: "",
    eventId: "",
    isPretest: false,
    isForGuruMadrasah: false,
  },
  createPelatihan: () => {},
  setPathThumbnail: () => {},
  setJenisPelatihanId: () => {},
  setSubMenuJenisPelatihanId: () => {},
  setWaktuMulai: () => {},
  setWaktuSelesai: () => {},
  setWaktuMulaiPendaftaran: () => {},
  setWaktuSelesaiPendaftaran: () => {},
  setPenyelenggara: () => {},
  setInformasiUmum: () => {},
  setKompetensiTeknis: () => {},
  setPersyaratan: () => {},
  setAngkatan: () => {},
  getJenisPelatihan: () => {},
  getSubMenuJenisPelatihan: () => {},
  setIsPPKB: () => {},
  setCreateManajemenPelatihan: () => {},
  setEventId: () => {},
  setIsPretest: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenPelatihanCreateProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    path_thumbnail: null,
    jenis_pelatihan_id: 0,
    sub_menu_jenis_pelatihan_id: 0,
    waktu_mulai: "",
    waktu_selesai: "",
    waktu_mulai_pendaftaran: "",
    waktu_selesai_pendaftaran: "",
    penyelenggara: "",
    informasi_umum: "",
    kompetensi_teknis: "",
    persyaratan: "",
    angkatan: "",
    jenisPelatihan: [],
    subMenuJenisPelatihan: [],
    msgError: [],
    isPPKB: false,
    materiInti: "",
    eventId: "",
    isPretest: false,
    isForGuruMadrasah: false,
  });

  const setCreateManajemenPelatihan = <K extends keyof IState>(
    key: K,
    value: IState[K]
  ) => {
    setState((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setIsPretest = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isPretest: value,
    }));
  };

  const setEventId = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      eventId: value,
    }));
  };

  const createPelatihan = async () => {
    setLoading(true);

    if (
      state.path_thumbnail == null ||
      state.jenis_pelatihan_id == 0 ||
      state.sub_menu_jenis_pelatihan_id == 0 ||
      state.waktu_mulai == "" ||
      state.waktu_selesai == "" ||
      state.waktu_mulai_pendaftaran == "" ||
      state.waktu_selesai_pendaftaran == "" ||
      state.penyelenggara == "" ||
      state.informasi_umum == "" ||
      state.persyaratan == "" ||
      state.angkatan == ""
    ) {
      toast("Data Belum Terisi Lengkap!");
      setLoading(false);
      return;
    }

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);

      const store = await pelatihanPresenter.storePelatihan(
        state.path_thumbnail,
        state.sub_menu_jenis_pelatihan_id,
        state.jenis_pelatihan_id,
        moment(state.waktu_mulai).format("YYYY-MM-DD HH:mm:ss"),
        moment(state.waktu_selesai).format("YYYY-MM-DD HH:mm:ss"),
        moment(state.waktu_mulai_pendaftaran).format("YYYY-MM-DD HH:mm:ss"),
        moment(state.waktu_selesai_pendaftaran).format("YYYY-MM-DD HH:mm:ss"),
        state.penyelenggara,
        state.informasi_umum,
        state.kompetensi_teknis,
        state.persyaratan,
        state.angkatan,
        state.isPPKB,
        state.isPPKB ? state.materiInti : "",
        state.eventId,
        state.isPretest,
        state.isForGuruMadrasah
      );

      const data = store;

      if (data.data.code == 200) {
        toast("Berhasil Menambahkan Data Baru.");

        history.replace(
          `/admin/kelola_konten/manajemen_pelatihan/${data.data.data.id}`
        );
        window.location.reload();
      } else {
        toast("Ada Kesalahan Saat Tambah Data!");
      }
      setLoading(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      toast("Ada Kesalahan Saat Tambah Data!");
      if (error.response.data?.errors) {
        setState((prevstate) => ({
          ...prevstate,
          msgError: [error.response.data?.errors],
        }));
      }
      setLoading(false);
    }
  };

  const setPathThumbnail = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      path_thumbnail: value,
    }));
  };

  const setJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      jenis_pelatihan_id: value,
    }));
  };

  const setSubMenuJenisPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      sub_menu_jenis_pelatihan_id: value,
    }));
  };

  const setWaktuMulai = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_mulai: value,
    }));
  };

  const setWaktuSelesai = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_selesai: value,
    }));
  };

  const setWaktuMulaiPendaftaran = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_mulai_pendaftaran: value,
    }));
  };

  const setWaktuSelesaiPendaftaran = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      waktu_selesai_pendaftaran: value,
    }));
  };

  const setPenyelenggara = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      penyelenggara: value,
    }));
  };

  const setInformasiUmum = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      informasi_umum: value,
    }));
  };

  const setKompetensiTeknis = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      kompetensi_teknis: value,
    }));
  };

  const setPersyaratan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      persyaratan: value,
    }));
  };

  const setAngkatan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      angkatan: value,
    }));
  };

  const setIsPPKB = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isPPKB: value,
    }));
  };

  const getJenisPelatihan = async (sub_menu_jenis_pelatihan_id: string) => {
    try {
      const jenisPelatihan = container.resolve(JenisPelatihanPresenter);

      const get = await jenisPelatihan.getAllJenisPelatihan(
        1,
        10000000,
        "",
        sub_menu_jenis_pelatihan_id
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        jenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Jenis Pelatihan:", error);
    }
  };

  const getSubMenuJenisPelatihan = async () => {
    try {
      const menuJenisPelatihanPresenter = container.resolve(
        MenuJenisPelatihanPresenter
      );

      const get = await menuJenisPelatihanPresenter.getAllSubMenuJenisPelatihan(
        1,
        10000000,
        ""
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        subMenuJenisPelatihan: data,
      }));
    } catch (error: any) {
      console.log("error get Sub Menu Jenis Pelatihan:", error);
    }
  };

  return (
    <ManajemenPelatihanCreateProvider
      value={{
        state,
        createPelatihan,
        setPathThumbnail,
        setJenisPelatihanId,
        setSubMenuJenisPelatihanId,
        setWaktuMulai,
        setWaktuSelesai,
        setWaktuMulaiPendaftaran,
        setWaktuSelesaiPendaftaran,
        setPenyelenggara,
        setInformasiUmum,
        setKompetensiTeknis,
        setPersyaratan,
        setAngkatan,
        getJenisPelatihan,
        getSubMenuJenisPelatihan,
        setIsPPKB,
        setCreateManajemenPelatihan,
        setEventId,
        setIsPretest,
      }}
    >
      {children}
    </ManajemenPelatihanCreateProvider>
  );
};

export const useManajemenPelatihanCreateContext = () =>
  React.useContext(Context);
// eslint-disable-next-line
export default {
  useManajemenPelatihanCreateContext,
  Provider,
};
