/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../../../data/presenters/PelatihanPresenter";
import { Pelatihan } from "../../../../../../../entities/Pelatihan";
import { toast } from "react-toastify";
import history from "../../../../../../../history";
import axiosInstance from "../../../../../../api/axiosInstance";

interface IState {
  isLoading: boolean;
  pelatihan: Pelatihan | null;
  showDialogDelete: boolean;
  selectedId: number;
  isLoadingDelete: boolean;
  isLoadingDuplicate: boolean;
  isOpenModalDuplicate: boolean;
}

interface InitialState {
  state: IState;
  getDetailPelatihan: Function;
  setShowDialogDelete: Function;
  setSelectedId: Function;
  deletePelatihan: Function;
  duplicatePelatihan: Function;
  setModalDuplicate: Function;
}

const initialState = {
  state: {
    isLoading: true,
    pelatihan: null,
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDelete: false,
    isLoadingDuplicate: false,
    isOpenModalDuplicate: false,
  },
  getDetailPelatihan: () => {},
  setShowDialogDelete: () => {},
  setSelectedId: () => {},
  deletePelatihan: () => {},
  duplicatePelatihan: () => {},
  setModalDuplicate: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: ManajemenPelatihanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    isLoadingDelete: false,
    pelatihan: null,
    showDialogDelete: false,
    selectedId: 0,
    isLoadingDuplicate: false,
    isOpenModalDuplicate: false,
  });

  const setModalDuplicate = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isOpenModalDuplicate: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDelete: value,
    }));
  };

  const setLoadingDuplicate = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingDuplicate: value,
    }));
  };

  const getDetailPelatihan = async (id: number) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        pelatihan: data,
      }));

      setLoading(false);

      return data;
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setSelectedId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      selectedId: value,
    }));
  };

  const deletePelatihan = async () => {
    setLoadingDelete(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const deletePelatihan = await pelatihanPresenter.deletePelatihan(
        state.selectedId
      );

      const data = deletePelatihan;

      if (data.data.code == 200) {
        toast.success("Berhasil Menghapus Data Pelatihan.", {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        history.replace(`/admin/manajemen_pelatihan`);
        window.location.reload();
      } else {
        toast.error("Gagal Menghapus Data Pelatihan.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setLoadingDelete(false);
      setShowDialogDelete(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setLoadingDelete(false);
    }
  };

  const getDetail = async (id) => {
    try {
      const response = await axiosInstance.get("pelatihan/" + id);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const duplicatePelatihan = async (id) => {
    setLoadingDuplicate(true);
    try {
      const detail = await getDetail(id);

      const payload = detail.m_modul_pelatihan.map((modul) => {
        return {
          judul_modul: modul.judul_modul,
          sequence: modul.sequence,
          sub_modul_pelatihan: modul.m_sub_modul_pelatihan.map((subModul) => {
            const parseMateri = JSON.parse(subModul.materi);
            return {
              materi: subModul.materi
                ? typeof parseMateri === "number"
                  ? [subModul.materi]
                  : parseMateri
                : [],
              ada_latihan: subModul.ada_latihan ?? false,
              jenis_sub_modul: subModul.jenis_sub_modul,
              judul: subModul.judul,
              jenis_ujian: subModul.jenis_ujian,
              paket_soal_id: subModul.paket_soal_id ?? 0,
              durasi_latihan: subModul.durasi_latihan,
              passing_grade: subModul.passing_grade ?? 0,
              sequence: subModul.sequence,
              bobot_penilaian: subModul.bobot_penilaian ?? 0,
              m_materi: subModul.m_materi,
              m_latihan: subModul.m_latihan,
              m_final_project: subModul.m_final_project,
              m_paket_soal: subModul.m_paket_soal,
            };
          }),
        };
      });

      const response = await axiosInstance.post(
        "duplicate/pelatihan/" + id,
        payload
      );

      setLoadingDuplicate(false);
      setModalDuplicate(false);
      toast.success("Berhasil Menduplikasi Data Pelatihan.", {
        style: {
          boxShadow: "0px 1px 6px #019166",
        },
      });
      history.replace(`/admin/manajemen_pelatihan`);
      window.location.reload();

      return response;
    } catch (error) {
      setLoadingDuplicate(false);
      toast.error(error.response.data?.message.error, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  return (
    <ManajemenPelatihanDetailProvider
      value={{
        state,
        getDetailPelatihan,
        setShowDialogDelete,
        setSelectedId,
        deletePelatihan,
        duplicatePelatihan,
        setModalDuplicate,
      }}
    >
      {children}
    </ManajemenPelatihanDetailProvider>
  );
};

export const useDetailPelatihanDataPelatihanContext = () =>
  React.useContext(Context);

export default {
  useDetailPelatihanDataPelatihanContext,
  Provider,
};
