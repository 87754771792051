import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  ModalFooter,
  IconButton,
} from "@chakra-ui/react";
import DataTable from "react-data-table-component";
import { BiSearch } from "react-icons/bi";
import SidebarWithHeader from "../../../../components/Admin/SidebarWithHeader";
import MasterTTEContext, {
  useMasterTTEContext,
} from "../../../../controller/admin/data_master/tte";
import Loader from "../../../../components/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";

/**
 * Represents a modal dialog for creating or editing master TTE accounts.
 * @component
 * @returns {JSX.Element} JSX.Element
 */

const ModalDialog = () => {
  const { mode, dataUpdate, openModal, loadingAction } =
    useMasterTTEContext().state;
  const { setMasterTTEState, postData } = useMasterTTEContext();

  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);

  const handleCloseModal = () => {
    setMasterTTEState("dataUpdate", null);
    setMasterTTEState("openModal", false);
  };

  return (
    <Modal isOpen={openModal} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="black">
          {mode === "create" ? "Tambah" : "Ubah"} Akun TTE
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontWeight="semibold" w="20%">
              Nama
            </Text>
            <Input
              w="70%"
              type="text"
              defaultValue={dataUpdate?.nama}
              onChange={(e) => setMasterTTEState("name", e.target.value)}
              readOnly
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mt={3}>
            <Text fontWeight="semibold" w="20%">
              NIK
            </Text>
            <Input
              w="70%"
              type="text"
              defaultValue={dataUpdate?.nik}
              onChange={(e) => setMasterTTEState("nik", e.target.value)}
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mt={3}>
            <Text fontWeight="semibold" w="20%">
              Jabatan
            </Text>
            <Input
              w="70%"
              type="text"
              defaultValue={dataUpdate?.jabatan}
              onChange={(e) => setMasterTTEState("jabatan", e.target.value)}
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" mt={3}>
            <Text fontWeight="semibold" w="20%">
              Passphrase
            </Text>
            <InputGroup size="md" w="70%">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Masukkan Passphrase"
                defaultValue={dataUpdate?.passphrase}
                onChange={(e) =>
                  setMasterTTEState("passphrase", e.target.value)
                }
              />
              <IconButton
                aria-label={
                  show ? "Sembunyikan passphrase" : "Perlihatkan passphrase"
                }
                icon={show ? <FaEyeSlash /> : <FaEye />}
                variant="ghost"
                size="sm"
                position="absolute"
                right="0.5rem"
                top="50%"
                transform="translateY(-50%)"
                _focus={{
                  ring: 0,
                }}
                onClick={handleClick}
              />
            </InputGroup>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} h="30px" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button
            colorScheme="green"
            h="30px"
            isLoading={loadingAction}
            spinnerPlacement="start"
            onClick={() => postData()}
          >
            Simpan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * Represents the main master TTE account page component.
 * This component displays the list of master TTE accounts in a data table,
 * along with search and pagination features. Users can create, edit, and delete
 * @component
 * @returns {JSX.Element} JSX.Element
 */
const MasterTTE = () => {
  const { listData, loadingData, pagination, page, perPage, keyword } =
    useMasterTTEContext().state;
  const { setMasterTTEState, fetchData, handleDelete } = useMasterTTEContext();

  const handlePageChange = (e) => {
    setMasterTTEState("page", e);
  };

  const handlePerRowsChange = (e) => {
    setMasterTTEState("perPage", e);
  };

  useEffect(() => {
    fetchData();
  }, [page, perPage, keyword]);

  const columns = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      grow: 0,
    },
    {
      name: "Nama",
      selector: (row) => row.nama,
      sortable: true,
    },
    {
      name: "NIK",
      selector: (row) => row.nik,
      sortable: true,
    },
    {
      name: "JABATAN",
      selector: (row) => row.jabatan ?? "-",
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <Button
              px="2"
              py="1"
              fontSize="xs"
              bg="green.100"
              variant="outline"
              rounded="full"
              h="24px"
              _hover={{ bg: "green.200" }}
              _active={{
                bg: "green.200",
              }}
              onClick={() => {
                setMasterTTEState("mode", "edit");
                setMasterTTEState("dataUpdate", row);
                setMasterTTEState("openModal", true);
                setMasterTTEState("nik", row.nik);
                setMasterTTEState("passphrase", row.passphrase);
              }}
            >
              Ubah Data
            </Button>
            <Button
              ml="2"
              px="2"
              py="1"
              fontSize="xs"
              bg="red.100"
              variant="outline"
              rounded="full"
              h="24px"
              _hover={{ bg: "red.200" }}
              _active={{
                bg: "red.200",
              }}
              onClick={() => {
                handleDelete(row.id);
              }}
            >
              Hapus Data
            </Button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <SidebarWithHeader>
      <Box boxShadow="lg" borderRadius="md" bg="white" px={4} py={8}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          px={4}
        >
          <Heading
            as="h2"
            fontSize="md"
            fontWeight="bold"
            lineHeight="normal"
            _hover={{ cursor: "pointer" }}
          >
            Akun TTE
          </Heading>
          <Box w={{ base: "full", md: "30%" }}>
            <Flex>
              <InputGroup mr={2}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={BiSearch} color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search"
                  _focus={{
                    ring: "1",
                    ringColor: "green",
                    borderColor: "green",
                  }}
                  onChange={(e) => {
                    setMasterTTEState("keyword", e.target.value);
                  }}
                />
              </InputGroup>
              <Button
                colorScheme="green"
                onClick={() => {
                  setMasterTTEState("mode", "create");
                  setMasterTTEState("openModal", true);
                }}
              >
                Tambah Baru
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Box mt={4}>
          <DataTable
            columns={columns}
            data={listData}
            progressPending={loadingData}
            pagination
            paginationServer
            paginationTotalRows={pagination ? pagination.total_rows : 0}
            paginationPerPage={perPage}
            progressComponent={<Loader />}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </Box>
      </Box>
      <ModalDialog />
    </SidebarWithHeader>
  );
};

/**
 * Represents the main Master TTE Account page component wrapped in the context provider.
 * @component
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */
const MasterTTEMain: React.FC = () => {
  return (
    <MasterTTEContext.Provider>
      <MasterTTE />
    </MasterTTEContext.Provider>
  );
};

export default MasterTTEMain;
