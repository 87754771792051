import React from "react";
import { Box, Text, Flex, Button, Tooltip, Divider } from "@chakra-ui/react";

import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";

import { usePengetahuanQuizContext } from "../../controller/pengetahuan/quiz";

import { parseISO, isAfter } from "date-fns";

/**
 * Quiz component for displaying quiz information and actions.
 *
 * @component
 * @example
 * @author Bagas Prasetyadi
 * return (
 *   <Quiz />
 * )
 */

const Quiz = () => {
  const { pengetahuanItem, isVideoEnded } = usePengetahuanDetailContext().state;

  const { startQuiz } = usePengetahuanQuizContext().dispatch;

  const listStatusShowQuiz = ["belum dikerjakan", "sedang dikerjakan"];

  const pengetahuanId = window.location.pathname.split("/", 4)[3];

  const endDate =
    pengetahuanItem?.jadwal_selesai &&
    parseISO(pengetahuanItem?.jadwal_selesai.replace(" ", "T"));

  const now = new Date();

  const isReadyToEndPengetahuanSync =
    pengetahuanItem?.jenis_pengetahuan === "synchronized" &&
    isAfter(now, endDate as Date);

  return (
    <>
      {pengetahuanItem?.paket_soal_id ? (
        <>
          <Box w="full" px={4}>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize="lg" fontWeight="semibold" mr={2}>
                Quiz
              </Text>
              <Text fontSize="sm">{pengetahuanItem?.total_soal ?? 0} Soal</Text>
            </Flex>
            {listStatusShowQuiz.includes(
              pengetahuanItem?.status_pengerjaan as string
            ) ? (
              <>
                <Text mt={2} fontSize="sm">
                  Anda dapat mengikuti Quiz dibawah ini
                </Text>
                <Flex>
                  {pengetahuanItem?.jenis_pengetahuan === "synchronized" && (
                    <Tooltip
                      label={
                        !isReadyToEndPengetahuanSync &&
                        "Tombol akan terbuka ketika sudah melewati jadwal selesai pengetahuan"
                      }
                      placement="top"
                      gutter={2}
                      maxW="none"
                      whiteSpace="nowrap"
                      hasArrow
                      isDisabled={isReadyToEndPengetahuanSync}
                    >
                      <Box>
                        <Button
                          mt={2}
                          colorScheme="blue"
                          disabled={!isReadyToEndPengetahuanSync}
                          onClick={() => {
                            startQuiz(
                              pengetahuanId,
                              pengetahuanItem?.status_pengerjaan
                            );
                          }}
                        >
                          Mulai Kerjakan Soal
                        </Button>
                      </Box>
                    </Tooltip>
                  )}

                  {pengetahuanItem?.jenis_pengetahuan === "asynchronized" && (
                    <Tooltip
                      label={
                        !isVideoEnded &&
                        "Tombol akan terbuka ketika sudah menyelesaikan video"
                      }
                      placement="top"
                      gutter={2}
                      maxW="none"
                      whiteSpace="nowrap"
                      hasArrow
                      isDisabled={
                        (isVideoEnded &&
                          pengetahuanItem?.category === "video") ||
                        pengetahuanItem?.category === "pdf"
                      }
                    >
                      <Box>
                        <Button
                          mt={2}
                          colorScheme="blue"
                          disabled={
                            !isVideoEnded &&
                            pengetahuanItem?.category === "video"
                          }
                          onClick={() => {
                            startQuiz(
                              pengetahuanId,
                              pengetahuanItem?.status_pengerjaan
                            );
                          }}
                        >
                          Mulai Kerjakan Soal
                        </Button>
                      </Box>
                    </Tooltip>
                  )}
                </Flex>
              </>
            ) : (
              <>
                <Text mt={2} fontSize="sm">
                  Anda sudah mengikuti quiz dan mendapatkan nilai{" "}
                  <span className="font-bold">
                    {" "}
                    {pengetahuanItem?.nilai_quiz ?? 0}
                  </span>
                </Text>
              </>
            )}
          </Box>
        </>
      ) : (
        <Text
          fontSize="md"
          fontWeight="semibold"
          mt={2}
          textAlign="center"
          w="full"
        >
          Tidak ada kuis pada pengetahuan ini
        </Text>
      )}
    </>
  );
};

export default Quiz;
