import React from "react";
import {
  Flex,
  Button,
  Text,
  Input,
  Grid,
  GridItem,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import { FaEdit } from "react-icons/fa";

import { useManajemenPengetahuanContext } from "../../../controller/admin/manajemen_pengetahuan";
import { useKelolaKontenManajemenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/kelola_konten";

type Props = {
  setShowModalLatihan: Function;
  setShowModalDetailQuiz: Function;
};

/**
 * Renders the BoxDetailQuiz component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setShowModalLatihan - A function to set the visibility of the Latihan modal.
 * @param {Function} props.setShowModalDetailQuiz - A function to set the visibility of the DetailQuiz modal.
 * @return {JSX.Element} The rendered BoxDetailQuiz component.
 * @author Bagas Prasetyadi
 */

const BoxDetailQuiz = ({
  setShowModalLatihan,
  setShowModalDetailQuiz,
}: Props) => {
  const { paketSoalId, duration } = useManajemenPengetahuanContext().state;
  const { setManajemenPengetahuanState } = useManajemenPengetahuanContext();

  const { optionsPaketSoal, paketSoal } =
    useKelolaKontenManajemenPelatihanContext().state;

  const soalSelected = optionsPaketSoal.find(
    (val) => val.value === paketSoalId
  );

  const paketSoalSelected = paketSoal.find((val) => val.id === paketSoalId);
  return (
    <>
      {paketSoalId ? (
        <>
          <Flex alignItems="center">
            <Text fontWeight="semibold" fontSize="md" mr={3}>
              Detail Quiz
            </Text>
            <IconButton
              aria-label="Edit Quiz"
              icon={<FaEdit />}
              color="green.400"
              bg="transparent"
              fontSize="md"
              onClick={() => {
                setShowModalLatihan(true);
                setManajemenPengetahuanState("modalQuizMode", "Ubah");
              }}
            />
          </Flex>
          <Divider my={4} />
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            {/* Paket Soal */}
            <GridItem colSpan={1}>Paket Soal</GridItem>
            <GridItem colSpan={4}>
              <Input
                fontSize="sm"
                bg="#F6F5F8"
                value={soalSelected?.label}
                readOnly
              />
            </GridItem>

            {/* Detail Soal */}
            <GridItem colSpan={1}>
              <Button
                colorScheme="green"
                w="full"
                onClick={() => setShowModalDetailQuiz(true)}
              >
                Detail Soal
              </Button>
            </GridItem>

            {/* Jumlah Soal */}
            <GridItem colSpan={1}>Jumlah Soal</GridItem>
            <GridItem colSpan={5}>
              <Text fontSize="sm">{paketSoalSelected?.soal?.length} Soal</Text>
            </GridItem>

            {/* Kategori Soal */}
            <GridItem colSpan={1}>Kategori Soal</GridItem>
            <GridItem colSpan={5}>
              <Text fontSize="sm">
                {paketSoalSelected?.kategori_paket_soal?.kategori_paket_soal}
              </Text>
            </GridItem>

            {/* Petunjuk Soal */}
            <GridItem colSpan={1}>Petunjuk Soal</GridItem>
            <GridItem colSpan={5}>
              <Text fontSize="sm">
                {parse(paketSoalSelected?.petunjuk_soal ?? "")}
              </Text>
            </GridItem>

            {/* Durai Latihan */}
            <GridItem colSpan={1}>Durasi Latihan</GridItem>
            <GridItem colSpan={5}>
              <Input fontSize="sm" value={duration} bg="#F6F5F8" readOnly />
            </GridItem>
          </Grid>
        </>
      ) : (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize="md" textAlign="center">
            Belum ada quiz
          </Text>
          <Button
            colorScheme="green"
            h={8}
            mt={2}
            onClick={() => {
              setShowModalLatihan(true);
              setManajemenPengetahuanState("modalQuizMode", "Tambah");
            }}
          >
            Tambakan Quiz
          </Button>
        </Flex>
      )}
    </>
  );
};

export default BoxDetailQuiz;
