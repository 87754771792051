/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { Wrapper } from "../../../components/Wrapper";
import Carousel from "react-multi-carousel";
import If, { Else } from "if-else-react";
import PelatihanController, {
  usePelatihanContext,
} from "../../../controller/pelatihan/index";
import PengetahuanController, {
  usePengetahuanContext,
} from "../../../controller/pengetahuan/index";
import PelatihanSayaController, {
  usePelatihanSayaContext,
} from "../../../controller/registered/pelatihan_saya";
import LandingDataController, {
  useLandingDataContext,
} from "../../../controller/public/landing_data";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import Skeleton from "react-loading-skeleton";
import { ToastContainer } from "react-toastify";
import HeroCarousel from "../../../components/LandingPage/HeroCarousel";
import SliderBannerPublicContext, {
  useSliderBannerPublicContext,
} from "../../../controller/public/slider_banner";
import TrainingKnowledge from "../../../components/LandingPage/TrainingKnowledge";
import AboutPintar from "../../../components/LandingPage/AboutPintar";
import InfografisPublicContext, {
  useInfografisPublicContext,
} from "../../../controller/public/infografis";
import Infografis from "../../../components/LandingPage/Infografis";
import EventPintarPublicContext, {
  useEventPintarPublicContext,
} from "../../../controller/public/event_pintar";
import EventPintar from "../../../components/LandingPage/EventPintar";
import { Box, Text, Link, Container } from "@chakra-ui/react";
import KnowledgeCenter from "../../../components/LandingPage/KnowledgeCenter";
import CountingSection from "../../../components/LandingPage/CountingSection";
import Faq from "../../../components/LandingPage/FrequentlyAsked";
import CardItemPelatihan from "../../../components/global/Card/CardItemPelatihan";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
  },
};

const TrainingSection: React.FC = () => {
  const { pelatihan, isLoading } = usePelatihanContext().state;

  return (
    <Box className="bg-white" bgGradient="linear(green.50 0%,  green.50 100%)">
      <Container maxW="8xl" py={{ base: 8, md: 12 }} w="full">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          w="full"
        >
          <Text
            as="h2"
            fontWeight="semibold"
            letterSpacing="tight"
            fontSize={{ base: "xl", md: "2xl" }}
          >
            Pelatihan Terbaru
          </Text>

          <Link
            href="/pelatihan"
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="semibold"
            color="green.700"
            _hover={{ color: "green.800" }}
          >
            Lihat Semua
          </Link>
        </Box>
        <Box mt={5}>
          <If condition={isLoading}>
            <Skeleton
              count={4}
              style={{ height: "400px", width: "280px", marginRight: "20px" }}
              inline={true}
            />
            <Else />
            <If condition={pelatihan.length > 0}>
              <Carousel responsive={responsive} ssr={true}>
                {pelatihan.map((v: Pelatihan, key) => (
                  <CardItemPelatihan pelatihan={v} key={v.id} />
                ))}
              </Carousel>
              <Else />
              <Box mx="auto" textAlign="center" my={20}>
                <Text as="h1" fontSize="xl" color="gray.400">
                  Belum Ada Pelatihan Terbaru
                </Text>
              </Box>
            </If>
          </If>
        </Box>
      </Container>
    </Box>
  );
};

const WrapperMain: React.FC = () => {
  const { getPelatihan } = usePelatihanContext();

  const { getMateri } = usePengetahuanContext();

  const { getLandingData } = useLandingDataContext();

  const { getCurrentDateServer } = usePelatihanSayaContext();

  const { fetchData: getSliderBanner } = useSliderBannerPublicContext();

  const { getInfografis } = useInfografisPublicContext();

  const { getEventPintar } = useEventPintarPublicContext();

  useEffect(() => {
    getCurrentDateServer().then(() => {
      getPelatihan(1, "");
      getMateri(1, "");
      getLandingData();
      getSliderBanner();
      getInfografis();
      getEventPintar();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {/* Carousel */}
      <HeroCarousel />

      {/* Pelatihan dan Knowledge */}
      <TrainingKnowledge />

      {/* About Pintar */}
      <AboutPintar />

      <TrainingSection />

      <KnowledgeCenter />

      <Infografis accessFor="public" />

      <EventPintar accessFor="public" />

      {/* Counting */}
      <CountingSection />

      <Faq />
    </Wrapper>
  );
};

const PageMainBeforeLogin: React.FC = () => {
  return (
    <PelatihanController.Provider>
      <PengetahuanController.Provider>
        <PelatihanSayaController.Provider>
          <LandingDataController.Provider>
            <SliderBannerPublicContext.Provider>
              <InfografisPublicContext.Provider>
                <EventPintarPublicContext.Provider>
                  <ToastContainer />
                  <WrapperMain />
                </EventPintarPublicContext.Provider>
              </InfografisPublicContext.Provider>
            </SliderBannerPublicContext.Provider>
          </LandingDataController.Provider>
        </PelatihanSayaController.Provider>
      </PengetahuanController.Provider>
    </PelatihanController.Provider>
  );
};

export default PageMainBeforeLogin;
