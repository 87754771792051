/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Wrapper } from "../../../components/Wrapper";
import LoginController, {
  useLoginContext,
} from "../../../controller/auth/login";
import { ToastContainer } from "react-toastify";
import {
  Heading,
  Box,
  Text,
  Link,
  Icon,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Checkbox,
  Divider,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import { FaUser, FaKey, FaEye, FaEyeSlash } from "react-icons/fa";
import env from "@ludovicm67/react-dotenv";

// Keycloak
import { useKeycloak } from "keycloak-react-web"

const LoginForm: React.FC = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { handleDoLogin, changeNip, changePassword } = useLoginContext();

  const { isLoading } = useLoginContext().state;

  const { keycloak } = useKeycloak();

  const toast = useToast();

  const handleErrorSSO = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const errorTitle = searchParams.get("errorTitle") ?? "";
    const errorMessage = searchParams.get("errorMessage") ?? "";

    if (!errorTitle) return;

    toast({
      title: decodeURIComponent(errorTitle),
      description: decodeURIComponent(errorMessage),
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const doLogin = (e) => {
    e.preventDefault();
    handleDoLogin();
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const urlRedirectSSOPusaka = env.REACT_APP_BE_PROD_BASE_URL.replace(
    "/api/v1/",
    "/oauth2/redirect"
  );

  const urlProdFE = env.REACT_APP_FE_PROD_BASE_URL;

  const isStaging = env.REACT_APP_ENVIRONMENT.toLowerCase() === "staging";

  useEffect(() => {
    handleErrorSSO();
  }, []);

  return (
    <Box textAlign="center" pb={18} pt={6} my={5}>
      <Heading
        fontSize={{ base: "2xl", md: "3xl" }}
        fontWeight="semibold"
        as="h1"
      >
        Selamat Datang di PINTAR
      </Heading>
      {/* Form Login */}
      <Box maxW={{ base: "85%", md: "33.3%" }} mx="auto" mt={8}>
        <Box bg="white" rounded="xl" overflow="hidden" mb={10}>
          <Box p={{ base: 4, md: 8 }}>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight={{ base: "normal", md: "semibold" }}
              color="#9ca3af"
              pb={5}
            >
              Masukkan NIP / NIK & password pada <br /> form di bawah ini dengan
              benar
            </Text>

            {/* Divider */}
            <Box borderBottom={2} borderStyle="dashed" color="gray.200"></Box>
            {/* /Divider */}

            {/* Form */}
            <form onSubmit={(e) => doLogin(e)}>
              <Box borderColor="gray.400" mt={4}>
                {/* NIP */}
                <Box>
                  <Text
                    textAlign="start"
                    mb={2}
                    fontSize="sm"
                    color="green.600"
                  >
                    NIP/NIK
                  </Text>
                  <InputGroup size="md" mb={4}>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FaUser} color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder="Masukkan NIP / NIK"
                      _placeholder={{ color: "gray.300" }}
                      onChange={(e) => changeNip(e.target.value)}
                      rounded="lg"
                      borderColor="gray.300"
                      _focus={{
                        ring: 1,
                        borderColor: "green.600",
                        ringColor: "green.600",
                      }}
                      className="nipInput"
                    />
                  </InputGroup>
                </Box>
                {/* Password */}
                <Box>
                  <Text
                    textAlign="start"
                    mb={2}
                    fontSize="sm"
                    color="green.600"
                  >
                    Password
                  </Text>
                  <InputGroup size="md" mb={4}>
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FaKey} color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Masukkan Password"
                      _placeholder={{ color: "gray.300" }}
                      onChange={(e) => changePassword(e.target.value)}
                      rounded="lg"
                      borderColor="gray.300"
                      _focus={{
                        ring: 1,
                        borderColor: "green.600",
                        ringColor: "green.600",
                      }}
                      pr="4.5rem"
                      className="passwordInput"
                    />
                    <IconButton
                      aria-label={
                        showPassword
                          ? "Sembunyikan kata sandi"
                          : "Perlihatkan kata sandi"
                      }
                      icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      variant="ghost"
                      size="sm"
                      position="absolute"
                      right="0.5rem"
                      top="50%"
                      transform="translateY(-50%)"
                      _focus={{
                        ring: 0,
                      }}
                      onClick={handleShowPassword}
                    />
                  </InputGroup>
                </Box>
              </Box>
              {/* /Form */}

              <Box textAlign="left" my={4}>
                <Link
                  href="/reset_password"
                  fontSize="sm"
                  color="green.600"
                  _hover={{ color: "green.700" }}
                >
                  Lupa Password?
                </Link>
              </Box>

              {/* Divider */}
              <Box borderBottom={2} borderStyle="dashed" color="gray.200"></Box>
              {/* /Divider */}

              {/* Footer Sign In */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={5}
              >
                <Checkbox
                  id="flexCheckDefault"
                  mt={1}
                  isChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  colorScheme="green"
                >
                  <Text fontSize="sm">Remember Me</Text>
                </Checkbox>
              </Box>

              <Button
                type="submit"
                w="full"
                mt={4}
                border="transparent"
                rounded="md"
                color="white"
                bg="green.700"
                _hover={{ bg: "green.800" }}
                _active={{ bg: "green.800" }}
                className="buttonLogin"
                isLoading={isLoading}
                loadingText="Loading..."
                fontSize={{ base: "sm", md: "md" }}
                fontWeight="medium"
              >
                Masuk
              </Button>

              {isStaging && (
                <>
                  <Box position="relative" p={8}>
                    <Divider />
                    <AbsoluteCenter bg="white" px={4}>
                      Atau
                    </AbsoluteCenter>
                  </Box>

                  <Button
                    type="button"
                    onClick={() => keycloak.login({
                      redirectUri: urlProdFE,
                    })}
                    w="full"
                    bg="facebook.500"
                    color="white"
                    href={urlRedirectSSOPusaka}
                    borderRadius="md"
                    display="inline-block"
                    py={2}
                    fontSize={{ base: "sm", md: "md" }}
                    _hover={{
                      textDecoration: "none",
                      bg: "facebook.600",
                    }}
                  >
                    Masuk Dengan SSO Pusaka
                  </Button>
                </>
              )}

              {/* /Footer Sign In */}
            </form>

            {/* Sign Up Link */}
            <Box mt={5}>
              <Text fontSize="md" color="gray.400" fontWeight="semibold">
                Belum memiliki akun pintar?
              </Text>
              <Link
                href="/register"
                fontSize="md"
                color="green.600"
                _hover={{ color: "green.700" }}
                fontWeight="semibold"
              >
                Buat Akun
              </Link>
            </Box>
            {/* /Sign Up Link */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const WrapperMain: React.FC = () => {
  return (
    <Wrapper>
      {/* Content */}
      <LoginForm />
    </Wrapper>
  );
};

const Login: React.FC = () => {
  return (
    <LoginController.Provider>
      <ToastContainer
        position="top-center"
        style={{ width: "60%", marginTop: "5%" }}
      />
      <WrapperMain />
    </LoginController.Provider>
  );
};

export default Login;
