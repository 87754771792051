import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../../../../api/axiosInstance";

interface TTEAccountItem {
  id: number;
  nama: string;
  nik: string;
  jabatan: string;
  passphrase: string;
  created_at: string;
  updated_at: string;
}

interface ListDataOption {
  label: string;
  value: string;
}

interface IState {
  state: {
    mode: string;
    name: string;
    nik: string;
    jabatan: string;
    passphrase: string;
    url: string;
    openModal: boolean;
    listData: TTEAccountItem[];
    dataUpdate: TTEAccountItem | null;
    loadingAction: boolean;
    loadingData: boolean;
    listDataOption: ListDataOption[];
    pagination: {
      current_page: number;
      total_rows: number;
    } | null;
    page: number;
    perPage: number;
    keyword: string;
  };
  setMasterTTEState: Function;
  fetchData: Function;
  postData: Function;
  handleDelete: Function;
}

const initialState: IState = {
  state: {
    mode: "",
    name: "",
    nik: "",
    jabatan: "",
    passphrase: "",
    url: "akun-tte",
    openModal: false,
    listData: [],
    dataUpdate: null,
    loadingAction: false,
    loadingData: false,
    listDataOption: [],
    pagination: null,
    page: 1,
    perPage: 10,
    keyword: "",
  },
  setMasterTTEState: () => {},
  fetchData: () => {},
  postData: () => {},
  handleDelete: () => {},
};

/**
 * The MasterTTEContext provides a context for managing the state related to Master TTE.
 * It includes state properties and functions to update the state and perform actions related to Master TTE.
 * @type {React.Context<IState>}
 */

const Context = createContext<IState>(initialState);
const { Provider: MasterTTEProvider } = Context;

/**
 * The provider component that wraps its children with the MasterTTEContext and manages the state.
 * @component
 * @param {React.FC} children - The child components that will have access to the context.
 * @returns {JSX.Element} JSX.Element
 * @author Bagas Prasetyadi
 */

const Provider: React.FC = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();

  const { url, mode, dataUpdate, page, perPage, nik, passphrase, jabatan, keyword } = state.state;

  const setMasterTTEState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async () => {
    setMasterTTEState("loadingData", true);

    try {
      const response = await axiosInstance.get(url, {
        params: {
          rows: perPage,
          page: page,
          keyword,
        },
      });
      const data = response.data?.data;
      setMasterTTEState("listData", data);
      setMasterTTEState("loadingData", false);

      const transformedData = data.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setMasterTTEState("listDataOption", transformedData);
      setMasterTTEState("pagination", response.data?.pagination);
    } catch (error) {
      setMasterTTEState("loadingData", false);
      throw error;
    }
  };

  const postData = async () => {
    setMasterTTEState("loadingAction", true);
    const newUrl = mode === "create" ? url : `${url}/${dataUpdate?.id}`;

    try {
      const response = await axiosInstance.post(newUrl, {
        nik,
        jabatan,
        passphrase,
      });
      setMasterTTEState("loadingAction", false);
      setMasterTTEState("openModal", false);

      fetchData();
      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setMasterTTEState("loadingAction", false);
      const responseData = error.response?.data;
      const titleMessage = responseData.message;

      toast({
        title: titleMessage,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`${url}/${id}`);

      toast({
        title: response.data?.message,
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });

      fetchData();

      return response.data;
    } catch (error) {
      toast({
        title: error?.message,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      throw error;
    }
  };

  return (
    <MasterTTEProvider
      value={{
        state: state.state,
        setMasterTTEState,
        fetchData,
        postData,
        handleDelete,
      }}
    >
      {children}
    </MasterTTEProvider>
  );
};

/**
 * A custom hook function to access the MasterTTEContext.
 * @returns {IState} The state and functions provided by the context.
 */

export const useMasterTTEContext = () => useContext(Context);

const MasterTTEContext = {
  useMasterTTEContext,
  Provider,
};

export default MasterTTEContext;
