import { injectable } from "tsyringe";
import { PelatihanApiRepository } from "../../app/repository/api/PelatihanApiRepository";
import { Pelatihan } from "../../entities/Pelatihan";

@injectable()
export class PelatihanPresenter {
  private repository: PelatihanApiRepository;
  constructor(repository: PelatihanApiRepository) {
    this.repository = repository;
  }

  public getAllPelatihan(
    page: number,
    rows: number,
    keyword: string,
    tahun: string,
    sub_menu_jenis_pelatihan_id: string,
    event_id: string | null
  ): Promise<Pelatihan[]> {
    return this.repository.getAllPelatihan(
      page,
      rows,
      keyword,
      tahun,
      sub_menu_jenis_pelatihan_id,
      event_id
    );
  }

  public getDetailPelatihan(id: number): Promise<Pelatihan> {
    return this.repository.getDetailPelatihan(id);
  }

  public storePelatihan(
    path_thumbnail: File,
    sub_menu_jenis_pelatihan_id: number,
    jenis_pelatihan_id: number,
    waktu_mulai: string,
    waktu_selesai: string,
    waktu_mulai_pendaftaran: string,
    waktu_selesai_pendaftaran: string,
    penyelenggara: string,
    informasi_umum: string,
    kompetensi_teknis: string,
    persyaratan: string,
    angkatan: string,
    is_ppkb: boolean,
    materi_inti: string,
    event_id: string,
    is_pretest: boolean,
    is_for_guru_madrasah: boolean
  ): Promise<any> {
    return this.repository.storePelatihan(
      path_thumbnail,
      sub_menu_jenis_pelatihan_id,
      jenis_pelatihan_id,
      waktu_mulai,
      waktu_selesai,
      waktu_mulai_pendaftaran,
      waktu_selesai_pendaftaran,
      penyelenggara,
      informasi_umum,
      kompetensi_teknis,
      persyaratan,
      angkatan,
      is_ppkb,
      materi_inti,
      event_id,
      is_pretest,
      is_for_guru_madrasah
    );
  }

  public updatePelatihan(
    id: number,
    path_thumbnail: any,
    sub_menu_jenis_pelatihan_id: number,
    jenis_pelatihan_id: number,
    waktu_mulai: string,
    waktu_selesai: string,
    waktu_mulai_pendaftaran: string,
    waktu_selesai_pendaftaran: string,
    penyelenggara: string,
    informasi_umum: string,
    kompetensi_teknis: string,
    persyaratan: string,
    angkatan: string,
    status_publish: boolean,
    is_ppkb: boolean,
    materi_inti: string,
    is_for_guru_madrasah: boolean
  ): Promise<any> {
    return this.repository.updatePelatihan(
      id,
      path_thumbnail,
      sub_menu_jenis_pelatihan_id,
      jenis_pelatihan_id,
      waktu_mulai,
      waktu_selesai,
      waktu_mulai_pendaftaran,
      waktu_selesai_pendaftaran,
      penyelenggara,
      informasi_umum,
      kompetensi_teknis,
      persyaratan,
      angkatan,
      status_publish,
      is_ppkb,
      materi_inti,
      is_for_guru_madrasah
    );
  }

  public updateStatusPublishPelatihan(
    id: number,
    status_publish: boolean
  ): Promise<any> {
    return this.repository.updateStatusPublishPelatihan(id, status_publish);
  }

  public deletePelatihan(id: number): Promise<any> {
    return this.repository.deletePelatihan(id);
  }

  public storeStatusSubModul(sub_modul_id: number): Promise<any> {
    return this.repository.storeStatusSubModul(sub_modul_id);
  }

  public createLatihan(
    sub_modul_pelatihan_id: number,
    materi_id: number,
    paket_soal_id: number,
    durasi_latihan: number,
    passing_grade: number,
    sequence: number
  ): Promise<any> {
    return this.repository.createLatihan(
      sub_modul_pelatihan_id,
      materi_id,
      paket_soal_id,
      durasi_latihan,
      passing_grade,
      sequence
    );
  }

  public updateLatihan(
    latihanId: number,
    paket_soal_id: number,
    durasi_latihan: number,
    passing_grade: number,
    sequence: number
  ): Promise<any> {
    return this.repository.updateLatihan(
      latihanId,
      paket_soal_id,
      durasi_latihan,
      passing_grade,
      sequence
    );
  }

  public deleteLatihan(id: number): Promise<any> {
    return this.repository.deleteLatihan(id);
  }
}
