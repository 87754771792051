import React from "react";
import { container } from "tsyringe";
import { SimdiklatPresenter } from "../../../../data/presenters/SimdiklatPresenter";
import {
  AgamaMasterSimdiklat,
  JabatanMasterSimdiklat,
  PangkatMasterSimdiklat,
  PelatihanSimdiklat,
  StatusKawinMasterSimdiklat,
  StatusPegawaiMasterSimdiklat,
} from "../../../../entities/Simdiklat";

interface IState {
  isLoading: boolean;
  listPelatihanSimdiklat: PelatihanSimdiklat[];
  listMasterPangkat: PangkatMasterSimdiklat[];
  listMasterJabatan: JabatanMasterSimdiklat[];
  listMasterAgama: AgamaMasterSimdiklat[];
  listMasterStatusKawin: StatusKawinMasterSimdiklat[];
  listMasterStatusPegawai: StatusPegawaiMasterSimdiklat[];
  error: boolean;
  msgError: string;
  page: number;
  limit: number;
}

interface InitialState {
  state: IState;
  getListPelatihanSimdiklat: Function;
  getListPelatihanByIdSimdiklat: Function;
  getListMasterPangkat: Function;
  getListMasterJabatan: Function;
  getListMasterAgama: Function;
  getListMasterStatusKawin: Function;
  getListMasterStatusPegawai: Function;
}

const initialState = {
  state: {
    isLoading: false,
    listPelatihanSimdiklat: [],
    listMasterPangkat: [],
    listMasterJabatan: [],
    listMasterAgama: [],
    listMasterStatusKawin: [],
    listMasterStatusPegawai: [],
    error: false,
    msgError: "",
    page: 1,
    limit: 100,
  },
  getListPelatihanSimdiklat: () => {},
  getListPelatihanByIdSimdiklat: () => {},
  getListMasterPangkat: () => {},
  getListMasterJabatan: () => {},
  getListMasterAgama: () => {},
  getListMasterStatusKawin: () => {},
  getListMasterStatusPegawai: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: SimdiklatProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    listPelatihanSimdiklat: [],
    listMasterPangkat: [],
    listMasterJabatan: [],
    listMasterAgama: [],
    listMasterStatusKawin: [],
    listMasterStatusPegawai: [],
    error: false,
    msgError: "",
    page: 1,
    limit: 100,
  });

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const getListPelatihanSimdiklat = async () => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getPelatihan(
        state.page,
        state.limit
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listPelatihanSimdiklat: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get pelatihan simdiklat:", error);
      setLoading(false);
    }
  };

  const getListPelatihanByIdSimdiklat = async (id: number) => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getPelatihanById(id);

      const data: PelatihanSimdiklat = get;

      setLoading(false);

      return data;
    } catch (error: any) {
      console.log("error get pelatihan simdiklat:", error);
      setLoading(false);
    }
  };

  const getListMasterPangkat = async () => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getMasterPangkat();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listMasterPangkat: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get master pangkat simdiklat:", error);
      setLoading(false);
    }
  };

  const getListMasterJabatan = async () => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getMasterJabatan();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listMasterJabatan: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get master jabatan simdiklat:", error);
      setLoading(false);
    }
  };

  const getListMasterAgama = async () => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getMasterAgama();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listMasterAgama: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get master agama simdiklat:", error);
      setLoading(false);
    }
  };

  const getListMasterStatusKawin = async () => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getMasterStatusKawin();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listMasterStatusKawin: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get master status kawin simdiklat:", error);
      setLoading(false);
    }
  };

  const getListMasterStatusPegawai = async () => {
    setLoading(true);

    try {
      const simdiklatPresenter = container.resolve(SimdiklatPresenter);
      const get = await simdiklatPresenter.getMasterStatusPegawai();

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        listMasterStatusPegawai: data,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error get master status pegawai simdiklat:", error);
      setLoading(false);
    }
  };

  return (
    <SimdiklatProvider
      value={{
        state,
        getListPelatihanSimdiklat,
        getListPelatihanByIdSimdiklat,
        getListMasterJabatan,
        getListMasterPangkat,
        getListMasterAgama,
        getListMasterStatusKawin,
        getListMasterStatusPegawai,
      }}
    >
      {children}
    </SimdiklatProvider>
  );
};

export const useSimdiklatContext = () => React.useContext(Context);
// eslint-disable-next-line
export default {
  useSimdiklatContext,
  Provider,
};
