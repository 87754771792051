import { Box, Flex, Text, Button, HStack, Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";
import { usePengetahuanAssesmentContext } from "../../controller/pengetahuan/assesment";
import ModalUploadAssesment from "../Admin/ModalPengetahuan/ModalUploadAssesment";
import { FaFileDownload, FaFilePdf, FaEdit } from "react-icons/fa";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { parseISO, differenceInHours } from "date-fns";

type Props = {};

/**
 * Renders the AssesmentPengetahuan component.
 *
 * @param {Props} props - The component props.
 * @return {JSX.Element} The rendered component.
 * @author Bagas Prasetyadi
 */
const AssesmentPengetahuan = (props: Props) => {
  const [tooglePreview, setTooglePreview] = useState<boolean>(false);
  const { pengetahuanItem } = usePengetahuanDetailContext().state;
  const { isLoadingDownload } = usePengetahuanAssesmentContext().state;
  const { downloadAssesment, setPengetahuanAsessmentState } =
    usePengetahuanAssesmentContext().dispatch;

  const pengetahuanId = window.location.pathname.split("/", 4)[3];

  const onOpenModal = (mode: string) => {
    setPengetahuanAsessmentState("isOpenModalUpload", true);
    setPengetahuanAsessmentState("modeModalAssessment", mode);
  };

  const startAssesmentSync =
    pengetahuanItem && parseISO(pengetahuanItem?.jadwal_selesai);
  const startAssesmentASync =
    pengetahuanItem &&
    (parseISO(pengetahuanItem?.tanggal_download_asesmen) as Date);
  const currentDate = new Date();

  const startAssesment =
    pengetahuanItem?.jenis_pengetahuan === "synchronized"
      ? startAssesmentSync
      : startAssesmentASync;

  let diffHours = 0;

  if (startAssesment instanceof Date) {
    diffHours = differenceInHours(currentDate, startAssesment);
  }

  //  hide upload file access after 5 days
  const hoursInFiveDays = 5 * 24;

  const shouldHideUploadFile = diffHours >= hoursInFiveDays;

  return (
    <>
      {pengetahuanItem?.has_assessment ? (
        <Box rounded="lg" w="full" px={4} bg="white">
          <Flex>
            <Text fontSize="lg" fontWeight="semibold" mr={2}>
              Assessment
            </Text>
          </Flex>
          <Text mt={2} fontSize="sm">
            Download assesment di bawah ini dan upload file yang sudah
            dikerjakan.
          </Text>
          <Button
            my={2}
            colorScheme="orange"
            onClick={() =>
              downloadAssesment(
                pengetahuanId,
                pengetahuanItem?.assessment?.judul,
                pengetahuanItem?.tanggal_download_asesmen,
                pengetahuanItem?.jenis_pengetahuan
              )
            }
            isLoading={isLoadingDownload}
            rightIcon={<FaFileDownload />}
          >
            Download Assessment
          </Button>

          {pengetahuanItem?.file_peserta ? (
            <>
              <Box
                borderWidth="1px"
                borderRadius="md"
                px={4}
                py={2}
                border="1px"
                borderStyle="dashed"
                borderColor="gray.500"
                mt={4}
              >
                <HStack spacing={2} justify="space-between" w="full">
                  <Flex alignItems="center">
                    <Icon as={FaFilePdf} mr={2} color="red.500" />
                    <Text fontSize="sm" textAlign="left" fontWeight="semibold">
                      File Jawaban Assessment
                    </Text>
                  </Flex>

                  <HStack spacing={2}>
                    <Icon
                      as={tooglePreview ? FiEyeOff : FiEye}
                      color={tooglePreview ? "gray" : "green"}
                      cursor="pointer"
                      onClick={() => setTooglePreview(!tooglePreview)}
                    />
                    {!shouldHideUploadFile && (
                      <Icon
                        as={FaEdit}
                        cursor="pointer"
                        color="blue"
                        onClick={() => onOpenModal("Edit")}
                      />
                    )}
                  </HStack>
                </HStack>

                {tooglePreview && (
                  <Box mt={2}>
                    <iframe
                      src={pengetahuanItem?.file_peserta}
                      width="100%"
                      height="400px"
                      title={`preview-assesment`}
                    />
                  </Box>
                )}
              </Box>

              <Text mt={2} color="blue" fontSize="xs">
                *Assessment sudah diunggah
              </Text>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={4}
              bg="#F6F5F8"
              p={4}
            >
              <Text fontSize="md" fontWeight="semibold">
                File Jawaban Upload disini
              </Text>
              <Text
                fontSize="sm"
                lineHeight="5"
                color="#6C757D"
                textAlign="center"
                mt={2}
              >
                Hanya bisa satu file. Maksimal ukuran file 2MB.
                <br />
                Format PDF.
              </Text>
              {!shouldHideUploadFile && (
                <Button
                  mt={2}
                  colorScheme="blue"
                  onClick={() => onOpenModal("Upload")}
                >
                  Upload Assessment
                </Button>
              )}
            </Box>
          )}
        </Box>
      ) : null}
      <ModalUploadAssesment />
    </>
  );
};

export default AssesmentPengetahuan;
