import React, { useState, useEffect } from "react";
import {
  FileAssesment,
  useManajemenPengetahuanContext,
} from "../../../controller/admin/manajemen_pengetahuan";
import MultiplePDFUpload from "../UploadFile/MultipleUploadFile";
import { useToast } from "@chakra-ui/react";

type Props = {
  showModalAssesment: boolean;
  setShowModalAssesment: Function;
};

/**
 * Props for ModalAssesment component.
 * @typedef {Object} Props
 * @property {boolean} showModalAssesment - Flag to show or hide the assessment modal.
 * @property {Function} setShowModalAssesment - Function to set the visibility of the assessment modal.
 */

/**
 * ModalAssesment component to display a modal for adding assessments.
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @author Bagas Prasetyadi
 */

const ModalAssesment = ({
  showModalAssesment,
  setShowModalAssesment,
}: Props) => {
  const { setManajemenPengetahuanState } = useManajemenPengetahuanContext();
  const {
    modalAssesmentMode,
    titleAssesment,
    descriptionAssesment,
    filesAssesment,
  } = useManajemenPengetahuanContext().state;

  const toast = useToast();

  const [tempTitleAssesment, setTempTitleAssesment] = useState("");
  const [tempDescriptionAssesment, setTempDescriptionAssesment] = useState("");
  const [tempFilesAssesment, setTempFilesAssesment] = useState<
    File[] | FileAssesment[]
  >([]);

  const setDefaultForm = () => {
    if (titleAssesment && descriptionAssesment) {
      setShowModalAssesment(false);
      return;
    }
    setTempTitleAssesment("");
    setTempDescriptionAssesment("");
    setTempFilesAssesment([]);
  };

  const handleSave = () => {
    if (!tempFilesAssesment.length) {
      toast({
        title: "Bahan Tugas wajib diisi",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }
    setManajemenPengetahuanState("titleAssesment", tempTitleAssesment);
    setManajemenPengetahuanState(
      "descriptionAssesment",
      tempDescriptionAssesment
    );
    setManajemenPengetahuanState("filesAssesment", tempFilesAssesment);
    setTempTitleAssesment("");
    setTempDescriptionAssesment("");
    setTempFilesAssesment([]);
    setShowModalAssesment(false);
  };

  useEffect(() => {
    if (titleAssesment && descriptionAssesment && showModalAssesment) {
      setTempTitleAssesment(titleAssesment);
      setTempDescriptionAssesment(descriptionAssesment);
      setTempFilesAssesment(filesAssesment);
    }
  }, [showModalAssesment]);
  return (
    <>
      {showModalAssesment ? (
        <>
          {/* Background Modal */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black w-full "></div>

          {/* Modal */}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full max-w-3xl p-4 md:h-auto">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                {/* Modal content */}
                <div className="flex items-start justify-between p-4 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {modalAssesmentMode} Assement
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent bg-red-400 hover:bg-red-600 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    onClick={() => {
                      setShowModalAssesment(false);
                      setDefaultForm();
                    }}
                    style={{ color: "white" }}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6 -mt-2">
                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block mb-2 text-gray-700 font-medium col-span-2">
                      Judul
                    </label>
                    <input
                      type="text"
                      className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => setTempTitleAssesment(e.target.value)}
                      value={tempTitleAssesment}
                    />
                  </div>

                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block mb-2 text-gray-700 font-medium col-span-2">
                      Deskripsi
                    </label>
                    <textarea
                      className="form-control
                          col-span-4
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) =>
                        setTempDescriptionAssesment(e.target.value)
                      }
                      value={tempDescriptionAssesment}
                    />
                  </div>

                  <div className="form-group grid grid-cols-6">
                    <label className="form-label inline-block py-1.5 text-gray-700 font-medium col-span-2">
                      Upload Bahan Tugas
                    </label>
                    <div className="col-span-4">
                      <MultiplePDFUpload
                        setFiles={setTempFilesAssesment}
                        defaultFiles={tempFilesAssesment}
                      />
                    </div>
                  </div>
                </div>
                <div className="items-center p-6 space-x-2 rounded-b flex justify-end -mt-3">
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-m col-span-2d hover:bg-gray-500 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                    onClick={() => {
                      setShowModalAssesment(false);
                      setDefaultForm();
                    }}
                  >
                    Batal
                  </button>
                  <button
                    type="button"
                    className="inline-block px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                    onClick={handleSave}
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ModalAssesment;
