import React, { createContext, useState, useContext } from "react";
import axiosInstance from "../../../../api/axiosInstance";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

interface JawabanSoal {
  jawaban_soal_id: number;
  soal_id: number;
  pilihan: string;
  jawaban_benar: number;
}

interface Soal {
  id: number;
  pengetahuan_paket_soal_id: number;
  soal_id: number;
  soal: string;
  created_at: string;
  updated_at: string;
  m_jawaban_soal: JawabanSoal[];
  jawaban_peserta: string;
}

interface IState {
  state: {
    url: string;
    bankSoal: Soal[];
    bankJawaban: JawabanSoal[];
    soalDipilih: string;
    indexSoal: number;
    jawabanPeserta: string;
    sisaWaktu: number | null;
    showModalFinishLatihan: boolean;
    paketSoalId: number | null;
    isLoadingFinishQuiz: boolean;
    isSavingAnswer: boolean;
    showScoreQuiz: boolean;
    scoreQuiz: number;
    timingFinish: boolean;
  };
  dispatch: {
    startQuiz: Function;
    fetchDataQuiz: Function;
    updateQuizTime: Function;
    onChangeAnswer: Function;
    setPengetahuanQuizState: Function;
    finishQuiz: Function;
  };
}

const initialState: IState = {
  state: {
    url: "/management_content/pengetahuan_saya/quiz/",
    bankSoal: [],
    bankJawaban: [],
    soalDipilih: "",
    indexSoal: 0,
    jawabanPeserta: "",
    sisaWaktu: null,
    showModalFinishLatihan: false,
    paketSoalId: null,
    isLoadingFinishQuiz: false,
    isSavingAnswer: false,
    showScoreQuiz: false,
    scoreQuiz: 0,
    timingFinish: false,
  },
  dispatch: {
    startQuiz: async () => {},
    fetchDataQuiz: async () => {},
    updateQuizTime: async () => {},
    onChangeAnswer: async () => {},
    setPengetahuanQuizState: () => {},
    finishQuiz: async () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: PengetahuanQuizProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const toast = useToast();
  const [state, setState] = useState<IState>(initialState);

  const { pengetahuanId } = useParams() as { pengetahuanId: string };

  const { bankSoal, indexSoal, bankJawaban, paketSoalId } = state.state;

  const setPengetahuanQuizState = (key: keyof IState["state"], value: any) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const { url } = state.state;
  const startQuiz = async (pengetahuanId, statusPengerjaan) => {
    if (statusPengerjaan === "sedang dikerjakan") {
      window.location.href = `/quiz/${pengetahuanId}`;
      return;
    }

    try {
      const response = await axiosInstance.post(url + "start", {
        pengetahuan_id: pengetahuanId,
      });

      window.location.href = `/quiz/${pengetahuanId}`;

      return response.data;
    } catch (error) {
      toast({
        title: error?.response?.data?.message ?? "Error saat memulai kuis",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const fetchDataQuiz = async (id: string, lastIndex) => {
    try {
      const response = await axiosInstance.get(url + id);
      setPengetahuanQuizState("bankSoal", response.data?.data?.soal ?? []);
      setPengetahuanQuizState(
        "bankJawaban",
        response.data?.data?.soal[lastIndex ?? 0]?.m_jawaban_soal ?? []
      );
      setPengetahuanQuizState(
        "soalDipilih",
        response.data?.data?.soal[lastIndex ?? 0]?.soal ?? ""
      );
      setPengetahuanQuizState("indexSoal", lastIndex ?? 0);
      setPengetahuanQuizState(
        "jawabanPeserta",
        response.data?.data?.soal[lastIndex ?? 0]?.jawaban_peserta ?? ""
      );

      setPengetahuanQuizState(
        "paketSoalId",
        response.data?.data?.soal[0]?.pengetahuan_paket_soal_id
      );
      setPengetahuanQuizState(
        "sisaWaktu",
        response.data?.data?.sisa_waktu_quiz
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const saveAnswer = async (value: string) => {
    setPengetahuanQuizState("isSavingAnswer", true);

    const jawabanSelected = bankJawaban.find(
      (item) => item.jawaban_soal_id.toString() === value
    );

    try {
      const response = await axiosInstance.post(url + "answer", {
        t_soal_id: bankSoal[indexSoal]?.id,
        jawaban_soal_id: jawabanSelected?.jawaban_soal_id,
        pilihan: jawabanSelected?.pilihan,
        jawaban_benar: jawabanSelected?.jawaban_benar,
      });

      return response.data?.data;
    } catch (error: any) {
      toast({
        title: error?.response?.data?.message ?? "Error saat memulai kuis",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setPengetahuanQuizState("isSavingAnswer", false);
    }
  };

  const finishQuiz = async () => {
    setPengetahuanQuizState("isLoadingFinishQuiz", true);
    try {
      const response = await axiosInstance.post(url + "finish", {
        pengetahuan_paket_soal_id: paketSoalId,
      });

      setPengetahuanQuizState(
        "scoreQuiz",
        response?.data?.data?.nilai_akhir ?? 0
      );
      setPengetahuanQuizState("showModalFinishLatihan", false);
      setPengetahuanQuizState("showScoreQuiz", true);

      return response?.data?.data;
    } catch (error) {
      toast({
        title: error?.response?.data?.message ?? "Error saat menyesaikan kuis",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      throw error;
    } finally {
      setPengetahuanQuizState("isLoadingFinishQuiz", false);
    }
  };

  const updateQuizTime = async (paketSoalId: number) => {
    try {
      const response = await axiosInstance.post(url + "update_waktu", {
        pengetahuan_paket_soal_id: paketSoalId,
      });

      setPengetahuanQuizState(
        "sisaWaktu",
        response.data?.data?.sisa_waktu ?? 0
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 400) {
        setPengetahuanQuizState("timingFinish", true);
      }
      throw error;
    }
  };

  const onChangeAnswer = async (jawabanId: string) => {
    const response = await saveAnswer(jawabanId);

    const bankSoalUpdate = bankSoal.map((item) => {
      if (item.id === response.id) {
        //update jawaban_peserta
        return { ...item, jawaban_peserta: jawabanId };
      }
      return item;
    });

    setPengetahuanQuizState("jawabanPeserta", jawabanId);
    setPengetahuanQuizState("bankSoal", bankSoalUpdate);

    fetchDataQuiz(pengetahuanId, indexSoal);
  };

  const dispatch = {
    startQuiz,
    fetchDataQuiz,
    updateQuizTime,
    onChangeAnswer,
    setPengetahuanQuizState,
    finishQuiz,
  };

  return (
    <PengetahuanQuizProvider value={{ state: state.state, dispatch }}>
      {children}
    </PengetahuanQuizProvider>
  );
};

export const usePengetahuanQuizContext = () => useContext(Context);

const PengetahuanQuizContext = {
  usePengetahuanQuizContext,
  Provider,
};

export default PengetahuanQuizContext;
