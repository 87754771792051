import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Icon,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { FiTrash2, FiEye, FiEyeOff } from "react-icons/fi";
import { FileAssesment } from "../../../controller/admin/manajemen_pengetahuan";

interface MultiplePDFUploadProps {
  setFiles: (files: File[]) => void;
  defaultFiles?: File[] | FileAssesment[];
}

/**
 * Renders a component for uploading multiple PDF files. Allows users to select files,
 * displays file previews, and handles file deletion.
 *
 * @param {MultiplePDFUploadProps} props - An object containing the following properties:
 *   - setFiles: A function to set the uploaded files.
 *   - defaultFiles: An optional array of default files to be uploaded.
 * @return {ReactElement} The rendered component.
 * @author Bagas Prasetyadi
 */

const MultiplePDFUpload: React.FC<MultiplePDFUploadProps> = ({
  setFiles,
  defaultFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [previewToggles, setPreviewToggles] = useState<boolean[]>([]);

  const MAX_FILE_SIZE_PDF = 2 * 1024 * 1024;
  const formatFile = ".pdf";

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const newFiles: File[] = [];
    const newErrors: string[] = [];
    const newPreviews: string[] = [];
    const newToggles: boolean[] = [];

    files.forEach((file) => {
      if (file.size > MAX_FILE_SIZE_PDF) {
        newErrors.push(`File ${file.name} melebihi ukuran maksimum 2MB.`);
      } else {
        newFiles.push(file);
        newToggles.push(false);
        const reader = new FileReader();
        reader.onloadend = () => {
          newPreviews.push(reader.result as string);
          if (newPreviews.length === newFiles.length) {
            setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
          }
        };
        reader.readAsDataURL(file);
      }
    });

    setErrors(newErrors);
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setPreviewToggles((prevToggles) => [...prevToggles, ...newToggles]);
    setFiles([...selectedFiles, ...newFiles]);
  };

  const handleDelete = (index: number) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    const updatedPreviews = previews.filter((_, i) => i !== index);
    const updatedToggles = previewToggles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    setPreviews(updatedPreviews);
    setPreviewToggles(updatedToggles);
    setFiles(updatedFiles);
  };

  const togglePreview = (index: number) => {
    setPreviewToggles((prevToggles) =>
      prevToggles.map((toggle, i) => (i === index ? !toggle : toggle))
    );
  };

  useEffect(() => {
    if (defaultFiles) {
      setSelectedFiles(defaultFiles as File[]);
      setPreviewToggles(defaultFiles.map(() => false));
      setPreviews(
        defaultFiles.map((file) =>
          file.url ? file.url : URL.createObjectURL(file)
        )
      );
    }
  }, [defaultFiles]);

  return (
    <Box>
      <Box
        borderWidth="1px"
        borderRadius="md"
        padding="4"
        textAlign="center"
        border="1px"
        borderStyle="dashed"
        borderColor="gray.500"
      >
        <Input
          type="file"
          accept={formatFile}
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="pdf-upload"
        />
        <label htmlFor="pdf-upload">
          <Button
            as="span"
            mt={2}
            colorScheme="blue"
            _hover={{ cursor: "pointer" }}
          >
            Pilih File
          </Button>
        </label>
        {errors.length > 0 && (
          <Box mt={2}>
            {errors.map((error, index) => (
              <Text key={index} color="red.500">
                {error}
              </Text>
            ))}
          </Box>
        )}

        <Text fontSize="sm" lineHeight="5" color="#6C757D" mt={4}>
          Hanya file PDF. Ukuran file maksimum adalah 2MB.
        </Text>
      </Box>
      <Box mt={4}>
        {selectedFiles.length > 0 && (
          <VStack mt={4} spacing={3}>
            {selectedFiles.map((file, index) => (
              <Box key={index} w="full">
                <Box
                  borderWidth="1px"
                  borderRadius="md"
                  px={4}
                  py={2}
                  textAlign="center"
                  border="1px"
                  borderStyle="dashed"
                  borderColor="gray.500"
                >
                  <HStack spacing={2} justify="space-between" w="full">
                    <Text fontSize="sm" textAlign="left">
                      {file.name}
                    </Text>
                    <HStack spacing={2}>
                      <Icon
                        as={previewToggles[index] ? FiEyeOff : FiEye}
                        cursor="pointer"
                        onClick={() => togglePreview(index)}
                      />
                      <Icon
                        as={FiTrash2}
                        color="#EB5757"
                        cursor="pointer"
                        onClick={() => handleDelete(index)}
                      />
                    </HStack>
                  </HStack>
                  {previews[index] && previewToggles[index] && (
                    <Box mt={2}>
                      <iframe
                        src={previews[index]}
                        width="100%"
                        height="300px"
                        title={`preview-${index}`}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default MultiplePDFUpload;
