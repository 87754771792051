/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { ModulPelatihanPresenter } from "../../../../../../data/presenters/ModulPelatihanPresenter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModulPelatihan } from "../../../../../../entities/ModulPelatihan";
import { PaketSoal } from "../../../../../../entities/PaketSoal";
import { Materi } from "../../../../../../entities/Materi";
import { MateriPresenter } from "../../../../../../data/presenters/MateriPresenter";
import { PaketSoalPresenter } from "../../../../../../data/presenters/PaketSoalPresenter";
import { Quiz } from "../../../../../../entities/Quiz";
import { QuizPresenter } from "../../../../../../data/presenters/QuizPresenter";
import { PelatihanPresenter } from "../../../../../../data/presenters/PelatihanPresenter";
import { Pelatihan } from "../../../../../../entities/Pelatihan";
import { FinalProjectPresenter } from "../../../../../../data/presenters/FinalProjectPresenter";
import { PathMateri } from "../../../../../../entities/PathMateri";
import { PathMateriPresenter } from "../../../../../../data/presenters/PathMateriPresenter";

interface IState {
  isLoading: boolean;
  jumlahPage: number;
  page: number;
  modulPelatihan: ModulPelatihan[];
  formId: number;
  formJudul: string;
  formSequence: number;
  formModulPelatihanId: number;
  formJenisSubModul: string;
  formMateriId: any[];
  formJenisUjian: string;
  formPaketSoalId: number;
  formSubModulDurasiLatihan: number;
  formSubModulPassingGrade: number;
  formLoading: boolean;
  showDialog: boolean;
  showDialogUpdate: boolean;
  showDialogDelete: boolean;
  jenisFormSection: string;
  paketSoal: PaketSoal[];
  materi: Materi[];
  typeDelete: string;
  pelatihanId: number;
  selectSubModulType: string;
  selectModulIdx: string;
  selectSubModulIdx: string;
  detailMateri: Materi | null;
  detailPaketSoal: PaketSoal | null;
  showDialogQuiz: boolean;
  showDialogDeleteQuiz: boolean;
  typeDialogFormQuiz: string;
  formWaktuKe: string;
  formPertanyaan: string;
  formPilihanA: string;
  formJawabanBenarA: boolean;
  formJawabanQuizIdA: number;
  formPilihanB: string;
  formJawabanBenarB: boolean;
  formJawabanQuizIdB: number;
  formPilihanC: string;
  formJawabanBenarC: boolean;
  formJawabanQuizIdC: number;
  formPilihanD: string;
  formJawabanBenarD: boolean;
  formJawabanQuizIdD: number;
  formPilihanE: string;
  formJawabanBenarE: boolean;
  formJawabanQuizIdE: number;
  quiz: Quiz[];
  selectedQuiz: Quiz | null;
  jawabanBenerQuiz: string;
  checkSectionMateriAdaLatihan: boolean;
  showDialogLatihan: boolean;
  showDialogDeleteLatihan: boolean;
  formPaketSoalIdLatihan: number;
  formDurasiLatihan: number;
  formPassingGradeLatihan: number;
  formLimitPengulangan: number;
  IndexPaketSoalIdLatihan: number;
  showModalDetailSoalLatihan: boolean;
  detailPelatihan: Pelatihan | null;
  formSubModulProjectPanduanPengerjaan: string;
  formSubModulProjectFilePanduan: any;
  subModulProjectFilePanduan: string | null;
  subModulProjectId: number;
  checkSubModulHaveFinalProject: number;
  pathMateri: PathMateri[] | null;
  indexPathMateri: number;
  onDragStart: boolean;
  optionsPaketSoal: {
    value: number;
    label: string;
  }[];
  isLoadingPaketSoal: boolean;
}

interface InitialState {
  state: IState;
  getModulPelatihan: Function;
  setFormDialog: Function;
  setUpdate: Function;
  actionFormDialog: Function;
  setFormId: Function;
  setFormJudul: Function;
  setFormSequence: Function;
  setShowDialog: Function;
  setShowDialogUpdate: Function;
  setShowDialogDelete: Function;
  deleteModulPelatihan: Function;
  setJenisFormSection: Function;
  setFormModulPelatihanId: Function;
  setFormJenisSubModul: Function;
  setFormMateriId: Function;
  setFormJenisUjian: Function;
  setFormPaketSoalId: Function;
  setFormSubModulDurasiLatihan: Function;
  setFormSubModulPassingGrade: Function;
  getMateri: Function;
  getPaketSoal: Function;
  setTypeDelete: Function;
  actionUpdate: Function;
  setSelectSection: Function;
  getDetailMateri: Function;
  getDetailPaketSoal: Function;
  selectSubModul: Function;
  setFormWaktuKe: Function;
  setFormPertanyaanQuiz: Function;
  setFormPilihanQuiz: Function;
  setFormJawabanBenarQuiz: Function;
  setShowDialogQuiz: Function;
  setShowDialogDeleteQuiz: Function;
  setTypeDialogFormQuiz: Function;
  getQuiz: Function;
  actionFormDialogQuiz: Function;
  deleteQuiz: Function;
  setSelectedQuiz: Function;
  clearForm: Function;
  setFormPaketSoalIdLatihan: Function;
  setFormDurasiLatihan: Function;
  setFormPassingGradeLatihan: Function;
  setShowDialogLatihan: Function;
  setShowDialogDeleteLatihan: Function;
  actionFormDialogLatihan: Function;
  deleteLatihan: Function;
  clickAdaLatihanMateri: Function;
  getIndexPaketSoalIdLatihan: Function;
  setShowModalDetailSoalLatihan: Function;
  getDetailPelatihan: Function;
  setFormSubModulProjectPanduanPengerjaan: Function;
  setFormSubModulProjectFilePanduan: Function;
  getFinalProject: Function;
  actionFinalProject: Function;
  setIndexPathMateri: Function;
  setOnDrag: Function;
  setFormLimitPengulangan: Function;
  setDefaultFormLatihan: Function;
}

const initialState = {
  state: {
    isLoading: true,
    jumlahPage: 0,
    page: 1,
    modulPelatihan: [],
    formId: 0,
    formJudul: "",
    formSequence: 0,
    formModulPelatihanId: 0,
    formJenisSubModul: "",
    formMateriId: [],
    formJenisUjian: "",
    formPaketSoalId: 0,
    formSubModulDurasiLatihan: 0,
    formSubModulPassingGrade: 0,
    formLoading: false,
    showDialog: false,
    showDialogUpdate: false,
    showDialogDelete: false,
    jenisFormSection: "modul",
    paketSoal: [],
    materi: [],
    typeDelete: "",
    pelatihanId: 0,
    selectSubModulType: "",
    selectModulIdx: "",
    selectSubModulIdx: "",
    detailMateri: null,
    detailPaketSoal: null,
    showDialogQuiz: false,
    showDialogDeleteQuiz: false,
    typeDialogFormQuiz: "",
    formWaktuKe: "",
    formPertanyaan: "",
    formPilihanA: "",
    formJawabanBenarA: true,
    formJawabanQuizIdA: 0,
    formPilihanB: "",
    formJawabanBenarB: false,
    formJawabanQuizIdB: 0,
    formPilihanC: "",
    formJawabanBenarC: false,
    formJawabanQuizIdC: 0,
    formPilihanD: "",
    formJawabanBenarD: false,
    formJawabanQuizIdD: 0,
    formPilihanE: "",
    formJawabanBenarE: false,
    formJawabanQuizIdE: 0,
    quiz: [],
    selectedQuiz: null,
    jawabanBenerQuiz: "a",
    checkSectionMateriAdaLatihan: false,
    showDialogLatihan: false,
    showDialogDeleteLatihan: false,
    formPaketSoalIdLatihan: 0,
    formDurasiLatihan: 0,
    formPassingGradeLatihan: 0,
    formLimitPengulangan: 0,
    IndexPaketSoalIdLatihan: 0,
    showModalDetailSoalLatihan: false,
    detailPelatihan: null,
    formSubModulProjectPanduanPengerjaan: "",
    formSubModulProjectFilePanduan: null,
    subModulProjectFilePanduan: null,
    subModulProjectId: 0,
    checkSubModulHaveFinalProject: 0,
    pathMateri: [],
    indexPathMateri: 0,
    onDragStart: false,
    optionsPaketSoal: [],
    isLoadingPaketSoal: false,
  },
  getModulPelatihan: () => {},
  setFormDialog: () => {},
  setUpdate: () => {},
  actionFormDialog: () => {},
  setFormId: () => {},
  setFormJudul: () => {},
  setFormSequence: () => {},
  setShowDialog: () => {},
  setShowDialogUpdate: () => {},
  setShowDialogDelete: () => {},
  deleteModulPelatihan: () => {},
  setJenisFormSection: () => {},
  setFormModulPelatihanId: () => {},
  setFormJenisSubModul: () => {},
  setFormMateriId: () => {},
  setFormJenisUjian: () => {},
  setFormPaketSoalId: () => {},
  setFormSubModulDurasiLatihan: () => {},
  setFormSubModulPassingGrade: () => {},
  getMateri: () => {},
  getPaketSoal: () => {},
  setTypeDelete: () => {},
  actionUpdate: () => {},
  setSelectSection: () => {},
  getDetailMateri: () => {},
  getDetailPaketSoal: () => {},
  selectSubModul: () => {},
  setFormWaktuKe: () => {},
  setFormPertanyaanQuiz: () => {},
  setFormPilihanQuiz: () => {},
  setFormJawabanBenarQuiz: () => {},
  setShowDialogQuiz: () => {},
  setShowDialogDeleteQuiz: () => {},
  setTypeDialogFormQuiz: () => {},
  getQuiz: () => {},
  actionFormDialogQuiz: () => {},
  deleteQuiz: () => {},
  setSelectedQuiz: () => {},
  clearForm: () => {},
  setFormPaketSoalIdLatihan: () => {},
  setFormDurasiLatihan: () => {},
  setFormPassingGradeLatihan: () => {},
  setShowDialogLatihan: () => {},
  setShowDialogDeleteLatihan: () => {},
  actionFormDialogLatihan: () => {},
  deleteLatihan: () => {},
  clickAdaLatihanMateri: () => {},
  getIndexPaketSoalIdLatihan: () => {},
  setShowModalDetailSoalLatihan: () => {},
  getDetailPelatihan: () => {},
  setFormSubModulProjectPanduanPengerjaan: () => {},
  setFormSubModulProjectFilePanduan: () => {},
  getFinalProject: () => {},
  actionFinalProject: () => {},
  setIndexPathMateri: () => {},
  setOnDrag: () => {},
  setFormLimitPengulangan: () => {},
  setDefaultFormLatihan: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: KelolaKontenManajemenPelatihanProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: false,
    jumlahPage: 0,
    page: 1,
    modulPelatihan: [],
    formId: 0,
    formJudul: "",
    formSequence: 0,
    formModulPelatihanId: 0,
    formJenisSubModul: "",
    formMateriId: [],
    formJenisUjian: "",
    formPaketSoalId: 0,
    formSubModulDurasiLatihan: 0,
    formSubModulPassingGrade: 0,
    formLoading: false,
    showDialog: false,
    showDialogUpdate: false,
    showDialogDelete: false,
    jenisFormSection: "modul",
    paketSoal: [],
    materi: [],
    typeDelete: "",
    pelatihanId: 0,
    selectSubModulType: "",
    selectModulIdx: "",
    selectSubModulIdx: "",
    detailMateri: null,
    detailPaketSoal: null,
    showDialogQuiz: false,
    showDialogDeleteQuiz: false,
    typeDialogFormQuiz: "",
    formWaktuKe: "",
    formPertanyaan: "",
    formPilihanA: "",
    formJawabanBenarA: true,
    formJawabanQuizIdA: 0,
    formPilihanB: "",
    formJawabanBenarB: false,
    formJawabanQuizIdB: 0,
    formPilihanC: "",
    formJawabanBenarC: false,
    formJawabanQuizIdC: 0,
    formPilihanD: "",
    formJawabanBenarD: false,
    formJawabanQuizIdD: 0,
    formPilihanE: "",
    formJawabanBenarE: false,
    formJawabanQuizIdE: 0,
    quiz: [],
    selectedQuiz: null,
    jawabanBenerQuiz: "a",
    checkSectionMateriAdaLatihan: false,
    showDialogLatihan: false,
    showDialogDeleteLatihan: false,
    formPaketSoalIdLatihan: 0,
    formDurasiLatihan: 0,
    formPassingGradeLatihan: 0,
    formLimitPengulangan: 0,
    IndexPaketSoalIdLatihan: 0,
    showModalDetailSoalLatihan: false,
    detailPelatihan: null,
    formSubModulProjectPanduanPengerjaan: "",
    formSubModulProjectFilePanduan: null,
    subModulProjectFilePanduan: null,
    subModulProjectId: 0,
    checkSubModulHaveFinalProject: 0,
    pathMateri: [],
    indexPathMateri: 0,
    onDragStart: false,
    optionsPaketSoal: [],
    isLoadingPaketSoal: false,
  });

  const setFormLimitPengulangan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formLimitPengulangan: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setOnDrag = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      onDragStart: value,
    }));
  };

  const getModulPelatihan = async (pelatihan_id: number) => {
    setLoading(true);

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );
      const getAllModulPelatihan =
        await modulPelatihanPresenter.getAllModulPelatihan(
          1,
          1000,
          "",
          pelatihan_id
        );

      const data = getAllModulPelatihan;

      setState((prevstate) => ({
        ...prevstate,
        modulPelatihan: data,
        pelatihanId: pelatihan_id,
      }));

      setLoading(false);
    } catch (error: any) {
      console.log("error paket soal:", error);
      setLoading(false);
    }
  };

  const setFormDialog = (value: string) => {
    clearForm();
    setState((prevstate) => ({
      ...prevstate,
      formDialog: value,
    }));
  };

  const setUpdate = (value: ModulPelatihan) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value.id,
      formJudul: value.judul_modul,
      formSequence: value.sequence != null ? Number(value.sequence) : 0,
    }));
  };

  const setFormLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      formLoading: value,
    }));
  };

  const setIndexPathMateri = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexPathMateri: value,
    }));
  };

  const actionFormDialog = async (pelatihan_id: number) => {
    setFormLoading(true);

    if (state.jenisFormSection == "modul") {
      if (state.formJudul == "") {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    } else if (state.jenisFormSection == "sub_modul_materi") {
      if (
        state.formModulPelatihanId == 0 ||
        state.formMateriId.length < 0 ||
        state.formJudul == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    } else if (state.jenisFormSection == "sub_modul_ujian_project") {
      if (state.formModulPelatihanId == 0 || state.formJudul == "") {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    } else if (state.jenisFormSection == "sub_modul_ujian_ujian") {
      if (
        state.formModulPelatihanId == 0 ||
        state.formPaketSoalId == 0 ||
        state.formJudul == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    }

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );

      if (state.jenisFormSection == "modul") {
        const action = await modulPelatihanPresenter.storeModulPelatihan(
          pelatihan_id,
          state.formJudul,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.jenisFormSection == "sub_modul_materi") {
        let listNumberMateriId: number[] = [];
        state.formMateriId.map((w) => {
          return listNumberMateriId.push(w);
        });

        const action = await modulPelatihanPresenter.storeSubModulPelatihan(
          state.jenisFormSection,
          state.formModulPelatihanId,
          "materi",
          listNumberMateriId,
          "",
          0,
          state.formJudul,
          0,
          0,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.jenisFormSection == "sub_modul_ujian_project") {
        const action = await modulPelatihanPresenter.storeSubModulPelatihan(
          state.jenisFormSection,
          state.formModulPelatihanId,
          "ujian",
          0,
          state.formJenisSubModul,
          0,
          state.formJudul,
          0,
          0,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.jenisFormSection == "sub_modul_ujian_ujian") {
        const action = await modulPelatihanPresenter.storeSubModulPelatihan(
          state.jenisFormSection,
          state.formModulPelatihanId,
          "ujian",
          0,
          state.formJenisSubModul,
          state.formPaketSoalId,
          state.formJudul,
          state.formSubModulDurasiLatihan,
          state.formSubModulPassingGrade,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error paket soal:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setFormJudul = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJudul: value,
    }));
  };

  const setFormSequence = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formSequence: Number(value),
    }));
  };

  const clearForm = () => {
    setState((prevstate) => ({
      ...prevstate,
      formId: 0,
      formJudul: "",
      formModulPelatihanId: 0,
      formJenisSubModul: "",
      formMateriId: [],
      formJenisUjian: "",
      formPaketSoalId: 0,
      jenisFormSection: "modul",
      typeDelete: "",
      formWaktuKe: "",
      formPertanyaan: "",
      formPilihanA: "",
      formJawabanBenarA: true,
      formJawabanQuizIdA: 0,
      formPilihanB: "",
      formJawabanBenarB: false,
      formJawabanQuizIdB: 0,
      formPilihanC: "",
      formJawabanBenarC: false,
      formJawabanQuizIdC: 0,
      formPilihanD: "",
      formJawabanBenarD: false,
      formJawabanQuizIdD: 0,
      formPilihanE: "",
      formJawabanBenarE: false,
      formJawabanQuizIdE: 0,
      formDurasiLatihan: 0,
      formPassingGradeLatihan: 0,
    }));
  };

  const deleteModulPelatihan = async () => {
    setFormLoading(true);

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );

      if (state.typeDelete == "modul") {
        const action = await modulPelatihanPresenter.deleteModulPelatihan(
          state.formId
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogDelete(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Hapus Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.typeDelete == "submodul") {
        const action = await modulPelatihanPresenter.deleteSubModulPelatihan(
          state.formId
        );

        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          setState((prevstate) => ({
            ...prevstate,
            modulPelatihan: [],
            selectSubModulIdx: "",
            selectSubModulType: "",
          }));

          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogDelete(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Hapus Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error paket soal:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setShowDialogDelete = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDelete: value,
    }));
  };

  const setShowDialog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialog: value,
    }));
  };

  const setShowDialogUpdate = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogUpdate: value,
    }));
  };

  const setJenisFormSection = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      jenisFormSection: value,
    }));
  };

  const setFormModulPelatihanId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formModulPelatihanId: value,
    }));
  };

  const setFormJenisSubModul = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJenisSubModul: value,
    }));
  };

  const setFormMateriId = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      formMateriId: [value],
    }));
  };

  const setFormJenisUjian = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formJenisUjian: value,
    }));
  };

  const setFormPaketSoalId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formPaketSoalId: value,
    }));
  };

  const setFormSubModulDurasiLatihan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formSubModulDurasiLatihan: value,
    }));
  };

  const setFormSubModulPassingGrade = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formSubModulPassingGrade: value,
    }));
  };

  const getMateri = async () => {
    try {
      const materiPresenter = container.resolve(MateriPresenter);
      const getAllMateri = await materiPresenter.getAllMateri(
        1,
        1000000,
        "",
        "",
        []
      );
      const data = getAllMateri;

      setState((prevstate) => ({
        ...prevstate,
        materi: data,
      }));
    } catch (error: any) {
      console.log("error materi:", error);
    }
  };

  const getPaketSoal = async () => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingPaketSoal: true,
    }));
    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const getAllPaketSoal = await paketSoalPresenter.getAllPaketSoal(
        1,
        1000000,
        "",
        "",
        "",
        ""
      );

      const data = getAllPaketSoal;

      setState((prevstate) => ({
        ...prevstate,
        paketSoal: data,
      }));

      const paketSoalWithQuestions =
        data.filter((paket) => paket.soal.length > 0) || [];

      const optionsPaketSoalSelected = paketSoalWithQuestions.map((item) => ({
        value: item.id,
        label: item.judul_soal,
      }));

      setState((prevstate) => ({
        ...prevstate,
        optionsPaketSoal: optionsPaketSoalSelected,
      }));
    } catch (error: any) {
      console.log("error materi:", error);
    } finally {
      setState((prevstate) => ({
        ...prevstate,
        isLoadingPaketSoal: false,
      }));
    }
  };

  const setTypeDelete = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      typeDelete: value,
    }));
  };

  const setFormId = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formId: value,
    }));
  };

  const actionUpdate = async () => {
    setFormLoading(true);

    if (state.jenisFormSection == "modul") {
      if (state.formJudul == "") {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    } else if (state.jenisFormSection == "sub_modul_materi") {
      if (
        state.formModulPelatihanId == 0 ||
        state.formMateriId.length == 0 ||
        state.formJudul == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    } else if (state.jenisFormSection == "sub_modul_ujian_project") {
      if (state.formModulPelatihanId == 0 || state.formJudul == "") {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    } else if (state.jenisFormSection == "sub_modul_ujian_ujian") {
      if (
        state.formModulPelatihanId == 0 ||
        state.formPaketSoalId == 0 ||
        state.formJudul == ""
      ) {
        toast.error("Data Belum Terisi Lengkap!", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
        setFormLoading(false);
        return;
      }
    }

    try {
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );

      if (state.jenisFormSection == "modul") {
        const action = await modulPelatihanPresenter.updateModulPelatihan(
          state.formId,
          state.formJudul,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogUpdate(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.jenisFormSection == "sub_modul_materi") {
        let listNumberMateriId: number[] = [];
        state.formMateriId.map((w) => {
          return listNumberMateriId.push(w);
        });

        const action = await modulPelatihanPresenter.updateSubModulPelatihan(
          state.jenisFormSection,
          state.formId,
          state.formModulPelatihanId,
          "materi",
          listNumberMateriId,
          "",
          0,
          state.formJudul,
          false,
          0,
          0,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setState((prevState) => ({
            ...prevState,
            selectSubModulType: "",
          }));
          setShowDialogUpdate(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.jenisFormSection == "sub_modul_ujian_project") {
        const action = await modulPelatihanPresenter.updateSubModulPelatihan(
          state.jenisFormSection,
          state.formId,
          state.formModulPelatihanId,
          "ujian",
          0,
          state.formJenisSubModul,
          0,
          state.formJudul,
          false,
          0,
          0,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogUpdate(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.jenisFormSection == "sub_modul_ujian_ujian") {
        const action = await modulPelatihanPresenter.updateSubModulPelatihan(
          state.jenisFormSection,
          state.formId,
          state.formModulPelatihanId,
          "ujian",
          0,
          state.formJenisSubModul,
          state.formPaketSoalId,
          state.formJudul,
          false,
          state.formSubModulDurasiLatihan,
          state.formSubModulPassingGrade,
          state.formSequence
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogUpdate(false);
          getModulPelatihan(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error paket soal:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setSelectSection = (idx: number, type: string, idx_sub: number) => {
    if (type == "modul") {
      setState((prevstate) => ({
        ...prevstate,
        formId: state.modulPelatihan[idx].id,
        formModulPelatihanId: state.modulPelatihan[idx].pelatihan_id,
        formJudul: state.modulPelatihan[idx].judul_modul,
        formSequence:
          state.modulPelatihan[idx].sequence != null
            ? Number(state.modulPelatihan[idx].sequence)
            : 0,
      }));
    } else if (type == "submodul") {
      if (
        state.modulPelatihan[idx].subModulPelatihan[idx_sub].jenis_sub_modul ==
        "materi"
      ) {
        const listFixMateriID: any = [];
        state.modulPelatihan[idx].subModulPelatihan[idx_sub].materi.map(
          (materi) => {
            return listFixMateriID.push(materi.materi.id);
          }
        );

        setState((prevstate) => ({
          ...prevstate,
          jenisFormSection: "sub_modul_materi",
          formMateriId: listFixMateriID,
        }));
      } else {
        if (
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].jenis_ujian ==
          "project"
        ) {
          setState((prevstate) => ({
            ...prevstate,
            jenisFormSection: "sub_modul_ujian_project",
          }));
        } else if (
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].jenis_ujian ==
          "ujian"
        ) {
          setState((prevstate) => ({
            ...prevstate,
            jenisFormSection: "sub_modul_ujian_ujian",
          }));
        }
      }

      setState((prevstate) => ({
        ...prevstate,
        formId: state.modulPelatihan[idx].subModulPelatihan[idx_sub].id,
        formModulPelatihanId: state.modulPelatihan[idx].id,
        formJudul: state.modulPelatihan[idx].subModulPelatihan[idx_sub].judul,
        formSequence:
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].sequence != null
            ? Number(
                state.modulPelatihan[idx].subModulPelatihan[idx_sub].sequence
              )
            : 0,
        formJenisSubModul:
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].jenis_sub_modul,
        formJenisUjian:
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].jenis_ujian,
        formPaketSoalId:
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].paket_soal_id,
        formSubModulDurasiLatihan:
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].durasi_latihan /
          60,
        formSubModulPassingGrade:
          state.modulPelatihan[idx].subModulPelatihan[idx_sub].passing_grade,
      }));
    }
  };

  const getDetailMateri = async (id: number) => {
    try {
      // Get Detail Materi
      const materiPresenter = container.resolve(MateriPresenter);
      const getDetailMateri = await materiPresenter.getDetailMateri(id);
      const data = getDetailMateri;

      // Get Path Materi
      const pathMateriPresenter = container.resolve(PathMateriPresenter);
      const get = await pathMateriPresenter.getAllPathMateri(id, "");
      const response = get;

      setState((prevstate) => ({
        ...prevstate,
        detailMateri: data,
        pathMateri: response,
      }));
    } catch (error: any) {
      console.log("error materi:", error);
    }
  };

  const getDetailPaketSoal = async (id: number) => {
    try {
      const paketSoalPresenter = container.resolve(PaketSoalPresenter);
      const getDetailPaketSoal = await paketSoalPresenter.getDetailPaketSoal(
        id
      );

      const data = getDetailPaketSoal;

      setState((prevstate) => ({
        ...prevstate,
        detailPaketSoal: data,
      }));
    } catch (error: any) {
      console.log("error paket soal:", error);
    }
  };

  const selectSubModul = (
    idxModul: number,
    idxSubModul: number,
    type: string
  ) => {
    setState((prevstate) => ({
      ...prevstate,
      selectSubModulType: type,
      selectModulIdx: idxModul.toString(),
      selectSubModulIdx: idxSubModul.toString(),
      detailPaketSoal: null,
      detailMateri: null,
      quiz: [],
    }));

    if (type == "sub_modul_materi") {
      if (
        state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
          .ada_latihan == 1
      ) {
        getIndexPaketSoalIdLatihan(
          Number(
            state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
              .m_latihan?.paket_soal_id
          ),
          state.paketSoal
        );

        setState((prevstate) => ({
          ...prevstate,
          formPassingGradeLatihan: Number(
            state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
              .m_latihan?.passing_grade
          ),
          formDurasiLatihan: Number(
            state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
              .m_latihan?.durasi_latihan
          ),
          formPaketSoalIdLatihan: Number(
            state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
              .m_latihan?.paket_soal_id
          ),
          formLimitPengulangan: Number(
            state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
              .m_latihan?.sequence
          ),
        }));
      }
      setState((prevstate) => ({
        ...prevstate,
        checkSectionMateriAdaLatihan:
          state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
            .ada_latihan == 1
            ? true
            : false,
      }));
      getDetailMateri(
        state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul].materi_id
      );
      getQuiz(state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul].id);
    } else if (type == "sub_modul_ujian_ujian") {
      getDetailPaketSoal(
        state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul]
          .paket_soal_id
      );
    } else if (type == "sub_modul_ujian_project") {
      getFinalProject(
        state.modulPelatihan[idxModul].subModulPelatihan[idxSubModul].id
      );
    }
  };

  const setFormWaktuKe = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formWaktuKe: value,
    }));
  };

  const setFormPertanyaanQuiz = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formPertanyaan: value,
    }));
  };

  const setFormPilihanQuiz = (value: string, pilihan: string) => {
    if (pilihan == "a") {
      setState((prevstate) => ({
        ...prevstate,
        formPilihanA: value,
      }));
    } else if (pilihan == "b") {
      setState((prevstate) => ({
        ...prevstate,
        formPilihanB: value,
      }));
    } else if (pilihan == "c") {
      setState((prevstate) => ({
        ...prevstate,
        formPilihanC: value,
      }));
    } else if (pilihan == "d") {
      setState((prevstate) => ({
        ...prevstate,
        formPilihanD: value,
      }));
    } else if (pilihan == "e") {
      setState((prevstate) => ({
        ...prevstate,
        formPilihanE: value,
      }));
    }
  };

  const setFormJawabanBenarQuiz = (value: string) => {
    if (value == "a") {
      setState((prevstate) => ({
        ...prevstate,
        formJawabanBenarA: true,
        formJawabanBenarB: false,
        formJawabanBenarC: false,
        formJawabanBenarD: false,
        formJawabanBenarE: false,
      }));
    } else if (value == "b") {
      setState((prevstate) => ({
        ...prevstate,
        formJawabanBenarA: false,
        formJawabanBenarB: true,
        formJawabanBenarC: false,
        formJawabanBenarD: false,
        formJawabanBenarE: false,
      }));
    } else if (value == "c") {
      setState((prevstate) => ({
        ...prevstate,
        jawaban_benar_a: false,
        jawaban_benar_b: false,
        jawaban_benar_c: true,
        jawaban_benar_d: false,
        jawaban_benar_e: false,
      }));
    } else if (value == "d") {
      setState((prevstate) => ({
        ...prevstate,
        formJawabanBenarA: false,
        formJawabanBenarB: false,
        formJawabanBenarC: false,
        formJawabanBenarD: true,
        formJawabanBenarE: false,
      }));
    } else if (value == "e") {
      setState((prevstate) => ({
        ...prevstate,
        formJawabanBenarA: false,
        formJawabanBenarB: false,
        formJawabanBenarC: false,
        formJawabanBenarD: false,
        formJawabanBenarE: true,
      }));
    }
  };

  const setShowDialogQuiz = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogQuiz: value,
    }));
  };

  const setShowDialogDeleteQuiz = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDeleteQuiz: value,
    }));
  };

  const setTypeDialogFormQuiz = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      typeDialogFormQuiz: value,
    }));
  };

  const getQuiz = async (sub_modul_id: number) => {
    try {
      const quizPresenter = container.resolve(QuizPresenter);
      const getAllQuiz = await quizPresenter.getAllQuiz(sub_modul_id);

      const data = getAllQuiz;

      setState((prevstate) => ({
        ...prevstate,
        quiz: data,
      }));
    } catch (error: any) {
      console.log("error Quiz:", error);
    }
  };

  const actionFormDialogQuiz = async () => {
    setFormLoading(true);

    try {
      const quizPresenter = container.resolve(QuizPresenter);

      if (state.typeDialogFormQuiz == "Tambah") {
        const action = await quizPresenter.storeQuiz(
          state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
            state.selectModulIdx
          ].id,
          Number(state.detailMateri?.id),
          state.formWaktuKe,
          state.formPertanyaan,
          state.formPilihanA,
          state.formJawabanBenarA,
          state.formPilihanB,
          state.formJawabanBenarB,
          state.formPilihanC,
          state.formJawabanBenarC,
          state.formPilihanD,
          state.formJawabanBenarD,
          state.formPilihanE,
          state.formJawabanBenarE
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          setState((prevstate) => ({
            ...prevstate,
            quiz: [],
          }));

          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogQuiz(false);
          getQuiz(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Tambah Quiz!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else {
        const action = await quizPresenter.updateQuiz(
          state.formId,
          state.formWaktuKe,
          state.formPertanyaan,
          state.formPilihanA,
          state.formJawabanBenarA,
          state.formJawabanQuizIdA,
          state.formPilihanB,
          state.formJawabanBenarB,
          state.formJawabanQuizIdB,
          state.formPilihanC,
          state.formJawabanBenarC,
          state.formJawabanQuizIdC,
          state.formPilihanD,
          state.formJawabanBenarD,
          state.formJawabanQuizIdD,
          state.formPilihanE,
          state.formJawabanBenarE,
          state.formJawabanQuizIdE
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          setState((prevstate) => ({
            ...prevstate,
            quiz: [],
          }));

          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialogQuiz(false);
          getQuiz(state.pelatihanId);
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data Quiz!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error quiz:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const deleteQuiz = async () => {
    setFormLoading(true);

    try {
      const quizPresenter = container.resolve(QuizPresenter);
      const deleteQuiz = await quizPresenter.deleteQuiz(state.formId);

      const data = deleteQuiz;

      if (data.data.code == 200) {
        toast.success(data.data.message, {
          style: {
            boxShadow: "0px 1px 6px #019166",
          },
        });
        setShowDialogDeleteQuiz(false);
        getQuiz(state.pelatihanId);
        clearForm();
      } else {
        toast.error("Gagal Menghapus Data Quiz.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }
      setFormLoading(false);
      setShowDialogDeleteQuiz(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setFormLoading(false);
    }
  };

  const setSelectedQuiz = async (q: Quiz) => {
    const opsiArray = ["a", "b", "c", "d", "e"];

    q.JawabanQuiz.forEach((j, idx) => {
      if (j.jawaban_benar == 1) {
        setState((prevstate) => ({
          ...prevstate,
          jawabanBener: opsiArray[idx],
        }));
      }

      return true;
    });

    setState((prevstate) => ({
      ...prevstate,
      selectedQuiz: q,
      formId: q.id,
      formWaktuKe: q.waktu_ke,
      formPertanyaan: q.pertanyaan,
      formPilihanA: q.JawabanQuiz[0].pilihan,
      formJawabanBenarA: q.JawabanQuiz[0].jawaban_benar == 1 ? true : false,
      formJawabanQuizIdA: q.JawabanQuiz[0].id,
      formPilihanB: q.JawabanQuiz[1].pilihan,
      formJawabanBenarB: q.JawabanQuiz[1].jawaban_benar == 1 ? true : false,
      formJawabanQuizIdB: q.JawabanQuiz[1].id,
      formPilihanC: q.JawabanQuiz[2].pilihan,
      formJawabanBenarC: q.JawabanQuiz[2].jawaban_benar == 1 ? true : false,
      formJawabanQuizIdC: q.JawabanQuiz[2].id,
      formPilihanD: q.JawabanQuiz[3].pilihan,
      formJawabanBenarD: q.JawabanQuiz[3].jawaban_benar == 1 ? true : false,
      formJawabanQuizIdD: q.JawabanQuiz[3].id,
      formPilihanE: q.JawabanQuiz[4].pilihan,
      formJawabanBenarE: q.JawabanQuiz[4].jawaban_benar == 1 ? true : false,
      formJawabanQuizIdE: q.JawabanQuiz[4].id,
    }));

    setTypeDialogFormQuiz("Ubah");
    setShowDialogQuiz(true);
  };

  const setFormPaketSoalIdLatihan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formPaketSoalIdLatihan: value,
    }));
  };

  const setFormDurasiLatihan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formDurasiLatihan: value,
    }));
  };

  const setFormPassingGradeLatihan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      formPassingGradeLatihan: value,
    }));
  };

  const setShowDialogLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogLatihan: value,
    }));
  };

  const setShowDialogDeleteLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showDialogDeleteLatihan: value,
    }));
  };

  const checkEmptyField = (field, errorMessage) => {
    if (!state[field]) {
      setFormLoading(false);
      toast.error(errorMessage, {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return true;
    }
    return false;
  };

  const actionFormDialogLatihan = async (type: string) => {
    setFormLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );

      let listNumberMateriId: number[] = [];
      state.formMateriId.map((w) => {
        return listNumberMateriId.push(w);
      });

      if (type == "Tambah") {
        if (
          checkEmptyField(
            "formDurasiLatihan",
            "Durasi latihan Tidak Boleh Kosong!"
          ) ||
          checkEmptyField(
            "formPassingGradeLatihan",
            "Passing Grade Tidak Boleh Kosong!"
          ) ||
          checkEmptyField(
            "formLimitPengulangan",
            "Limit Pengulangan Tidak Boleh Kosong!"
          )
        ) {
          return;
        }

        const action = await modulPelatihanPresenter.updateSubModulPelatihan(
          state.jenisFormSection,
          state.formId,
          state.formModulPelatihanId,
          "materi",
          listNumberMateriId,
          "",
          0,
          state.formJudul,
          true,
          state.formSubModulDurasiLatihan,
          state.formSubModulPassingGrade,
          state.formSequence
        );
        const data = action;

        if (data.data.code == 200) {
          const action2 = await pelatihanPresenter.createLatihan(
            state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
              state.selectSubModulIdx
            ].id,
            state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
              state.selectSubModulIdx
            ].materi_id,
            state.formPaketSoalIdLatihan,
            state.formDurasiLatihan,
            state.formPassingGradeLatihan,
            state.formLimitPengulangan
          );
          const data2 = action2;

          if (data2.data.code == 200) {
            setState((prevstate) => ({
              ...prevstate,
              modulPelatihan: [],
              checkSectionMateriAdaLatihan: true,
            }));

            toast.success(data.data.message, {
              style: {
                boxShadow: "0px 1px 6px #019166",
              },
            });
            setShowDialogLatihan(false);
            getModulPelatihan(state.pelatihanId);
            setFormLoading(false);
          } else {
            toast.error("Ada Kesalahan Saat Tambah Latihan!", {
              style: {
                boxShadow: "0px 1px 6px #F86E70",
              },
            });
          }
        } else {
          toast.error("Ada Kesalahan Saat Tambah Latihan!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else {
        const action = await pelatihanPresenter.updateLatihan(
          state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
            state.selectSubModulIdx
          ].m_latihan.id,
          state.formPaketSoalIdLatihan,
          state.formDurasiLatihan,
          state.formPassingGradeLatihan,
          state.formLimitPengulangan
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          setState((prevstate) => ({
            ...prevstate,
            modulPelatihan: [],
            checkSectionMateriAdaLatihan: true,
          }));
          getModulPelatihan(state.pelatihanId);
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data Latihan!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);
      console.log("error latihan:", error);
      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const deleteLatihan = async () => {
    setFormLoading(true);

    let listNumberMateriId: number[] = [];
    state.formMateriId.map((w) => {
      return listNumberMateriId.push(w);
    });

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const modulPelatihanPresenter = container.resolve(
        ModulPelatihanPresenter
      );

      const action = await modulPelatihanPresenter.updateSubModulPelatihan(
        state.jenisFormSection,
        state.formId,
        state.formModulPelatihanId,
        "materi",
        listNumberMateriId,
        "",
        0,
        state.formJudul,
        false,
        state.formSubModulDurasiLatihan,
        state.formSubModulPassingGrade,
        state.formSequence
      );
      const data = action;

      if (data.data.code == 200) {
        const deleteLatihan = await pelatihanPresenter.deleteLatihan(
          state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
            state.selectSubModulIdx
          ].m_latihan.id
        );

        const data = deleteLatihan;

        if (data.data.code == 200) {
          setState((prevstate) => ({
            ...prevstate,
            modulPelatihan: [],
            checkSectionMateriAdaLatihan: false,
          }));
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setDefaultFormLatihan();
          setShowDialogDeleteLatihan(false);
          getModulPelatihan(state.pelatihanId);
        } else {
          toast.error("Gagal Menghapus Data Latihan.", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else {
        toast.error("Gagal Menghapus Data Latihan.", {
          style: {
            boxShadow: "0px 1px 6px #F86E70",
          },
        });
      }

      setFormLoading(false);
      setShowDialogDeleteLatihan(false);
    } catch (error: any) {
      console.log("error pelatihan:", error);
      setFormLoading(false);
    }
  };

  const clickAdaLatihanMateri = (value: boolean) => {
    if (value) {
      setShowDialogLatihan(true);
    } else {
      setShowDialogDeleteLatihan(true);
    }
  };

  const getIndexPaketSoalIdLatihan = (value: number, arr: any) => {
    arr.map((p, i) => {
      if (p.id === value) {
        setState((prevstate) => ({
          ...prevstate,
          IndexPaketSoalIdLatihan: i,
        }));
      }

      return true;
    });
  };

  const setShowModalDetailSoalLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalDetailSoalLatihan: value,
    }));
  };

  const getDetailPelatihan = async (id: number) => {
    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);

      const data = getDetail;

      setState((prevstate) => ({
        ...prevstate,
        detailPelatihan: data,
      }));

      return data;
    } catch (error: any) {
      console.log("error get pelatihan:", error);
    }
  };

  const setFormSubModulProjectPanduanPengerjaan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      formSubModulProjectPanduanPengerjaan: value,
    }));
  };

  const setFormSubModulProjectFilePanduan = (value) => {
    setState((prevstate) => ({
      ...prevstate,
      formSubModulProjectFilePanduan: value,
    }));
  };

  const getFinalProject = async (id: number) => {
    try {
      const finalProjectPresenter = container.resolve(FinalProjectPresenter);
      const getDetail = await finalProjectPresenter.getDetailFinalProjectAdmin(
        id
      );

      const data = getDetail;

      if (data != null) {
        setState((prevstate) => ({
          ...prevstate,
          subModulProjectId: data.id,
          formSubModulProjectPanduanPengerjaan: data.panduan_pengerjaan,
          subModulProjectFilePanduan: data.path_panduan,
          checkSubModulHaveFinalProject: 1,
        }));
      } else {
        setState((prevstate) => ({
          ...prevstate,
          checkSubModulHaveFinalProject: 0,
        }));
      }
    } catch (error: any) {
      console.log("error get pelatihan:", error);
    }
  };

  const actionFinalProject = async () => {
    setFormLoading(true);

    if (
      state.formSubModulProjectPanduanPengerjaan == "" ||
      state.formSubModulProjectFilePanduan == null
    ) {
      toast.error("Data Belum Terisi Lengkap!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
      return;
    }

    try {
      const finalProjectPresenter = container.resolve(FinalProjectPresenter);
      if (state.checkSubModulHaveFinalProject == 0) {
        const action = await finalProjectPresenter.storeFinalProjectAdmin(
          state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
            state.selectSubModulIdx
          ].id,
          state.formSubModulProjectPanduanPengerjaan,
          state.formSubModulProjectFilePanduan[0]
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getFinalProject(
            state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
              state.selectSubModulIdx
            ].id
          );
        } else {
          toast.error("Ada Kesalahan Saat Tambah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      } else if (state.checkSubModulHaveFinalProject == 1) {
        const action = await finalProjectPresenter.updateFinalProjectAdmin(
          state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
            state.selectSubModulIdx
          ].id,
          state.formSubModulProjectPanduanPengerjaan,
          state.formSubModulProjectFilePanduan != null
            ? state.formSubModulProjectFilePanduan[0]
            : null
        );
        const data = action;

        setFormLoading(false);

        if (data.data.code == 200) {
          toast.success(data.data.message, {
            style: {
              boxShadow: "0px 1px 6px #019166",
            },
          });
          setShowDialog(false);
          getFinalProject(
            state.modulPelatihan[state.selectModulIdx].subModulPelatihan[
              state.selectSubModulIdx
            ].id
          );
          clearForm();
        } else {
          toast.error("Ada Kesalahan Saat Ubah Data!", {
            style: {
              boxShadow: "0px 1px 6px #F86E70",
            },
          });
        }
      }
    } catch (error: any) {
      setFormLoading(false);

      toast.error("Ada Kesalahan, Silahkan Coba Lagi!", {
        style: {
          boxShadow: "0px 1px 6px #F86E70",
        },
      });
    }
  };

  const setDefaultFormLatihan = () => {
    setFormDurasiLatihan(0);
    setFormPassingGradeLatihan(0);
    setFormLimitPengulangan(0);
  };

  return (
    <KelolaKontenManajemenPelatihanProvider
      value={{
        state,
        getModulPelatihan,
        setFormDialog,
        setUpdate,
        actionFormDialog,
        setFormId,
        setFormJudul,
        setFormSequence,
        setShowDialog,
        setShowDialogUpdate,
        setShowDialogDelete,
        deleteModulPelatihan,
        setJenisFormSection,
        setFormModulPelatihanId,
        setFormJenisSubModul,
        setFormMateriId,
        setFormJenisUjian,
        setFormPaketSoalId,
        setFormSubModulDurasiLatihan,
        setFormSubModulPassingGrade,
        getMateri,
        getPaketSoal,
        setTypeDelete,
        actionUpdate,
        setSelectSection,
        getDetailMateri,
        getDetailPaketSoal,
        selectSubModul,
        setFormWaktuKe,
        setFormPertanyaanQuiz,
        setFormPilihanQuiz,
        setFormJawabanBenarQuiz,
        setShowDialogQuiz,
        setShowDialogDeleteQuiz,
        setTypeDialogFormQuiz,
        getQuiz,
        actionFormDialogQuiz,
        deleteQuiz,
        setSelectedQuiz,
        clearForm,
        setFormPaketSoalIdLatihan,
        setFormDurasiLatihan,
        setFormPassingGradeLatihan,
        setShowDialogLatihan,
        setShowDialogDeleteLatihan,
        actionFormDialogLatihan,
        deleteLatihan,
        clickAdaLatihanMateri,
        getIndexPaketSoalIdLatihan,
        setShowModalDetailSoalLatihan,
        getDetailPelatihan,
        setFormSubModulProjectPanduanPengerjaan,
        setFormSubModulProjectFilePanduan,
        getFinalProject,
        actionFinalProject,
        setIndexPathMateri,
        setOnDrag,
        setFormLimitPengulangan,
        setDefaultFormLatihan,
      }}
    >
      {children}
    </KelolaKontenManajemenPelatihanProvider>
  );
};

export const useKelolaKontenManajemenPelatihanContext = () =>
  React.useContext(Context);
// eslint-disable-next-line
export default {
  useKelolaKontenManajemenPelatihanContext,
  Provider,
};
